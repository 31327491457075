import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  width: 100vw;
`;

export const ContainerDatails = styled.div`
  width: 60%;
  background-color: var(--blue-card);
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 1rem;
  padding-bottom: 0;

  @media (max-width: 1400px) {
    width: 90%;
  }

  div {
    width: 50%;
    margin-bottom: 1rem;

    button {
      background-color: var(--primary);
      padding: 0.5rem 1rem;
      color: var(--light-gray);
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
    }
  }

  span {
    color: var(--light-gray);
    font-size: 1.3rem;
    width: 50%;
  }

  span.user {
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem;
  align-items: center;

  @media (max-width: 1400px) {
    padding: 1rem;
  }
`;

export const ContainerReport = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ContainerDenouncedProfile = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 20px;
  background-color: var(--blue-card);

  @media (max-width: 800px) {
    margin-bottom: 2rem;
  }

  span {
    color: var(--light-gray);
    font-size: 1.4rem;
  }
  p {
    color: var(--light-gray);
    font-size: 1.2rem;
  }
`;

export const DenouncedImage = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  div.image {
    border-radius: 5px 5px 0 0;
    height: 20rem;
    background-color: var(--dark-gray);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.controll {
    display: flex;
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: var(--light-gray);
    justify-content: space-around;
    border-radius: 0 0 5px 5px;

    span {
      color: var(--graphite);
      font-size: 1.2rem;
    }

    button {
      border: none;
      background-color: transparent;
      width: 3rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`;

export const ContainerMessages = styled(ContainerDenouncedProfile)`
  span {
    font-size: 1.2rem;
  }
`;

export const Chat = styled.div`
  width: 100%;
  height: 30rem;
  padding: 1rem;
  background-color: var(--light-gray);
  border-radius: 5px;
  overflow-y: auto;
`;

export const ContainerMessageMe = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  div {
    background-color: var(--primary);
    padding: 0.5rem 0.4rem 0.5rem 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 10px;
  }

  p {
    color: var(--light-gray);
    font-size: 1rem;
  }

  span {
    color: var(--black);
    font-size: 0.6rem;
    margin-left: 0.3rem;
  }
`;

export const ContainerMessageUser = styled.div`
  display: flex;
  margin-bottom: 1rem;

  div {
    background-color: var(--secondary);
    padding: 0.5rem 0.4rem 0.5rem 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 10px;
  }

  p {
    color: var(--light-gray);
    font-size: 1rem;
  }

  span {
    color: var(--gray);
    font-size: 0.6rem;
    margin-left: 0.3rem;
  }
`;

export const ContainerOptions = styled.div`
  width: 100%;
  background-color: var(--blue-card);
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 1rem;
`;

export const ContainerChangeStatus = styled.div`
  display: flex;
  align-items: center;

  span.option {
    color: var(--light-gray);
    margin-right: 1rem;
    font-size: 1.2rem;
  }

  input {
    margin-right: 0.5rem;
  }

  label {
    display: flex;
    align-items: center;
    color: var(--light-gray);
  }

  button {
    background-color: var(--primary);
    border: none;
    border-radius: 2rem;
    color: var(--light-gray);
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const ContainerActions = styled.div`
  display: flex;
  margin-top: 1rem;

  span {
    color: var(--light-gray);
    margin-right: 1rem;
    font-size: 1.2rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--light-gray);
  border-radius: 10px;
  padding: 0.7rem 1.5rem 0 1.5rem;
  width: 40rem;

  button {
    background-color: var(--primary);
    border: none;
    border-radius: 2rem;
    color: var(--light-gray);
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    width: 17rem;
    margin-bottom: 1rem;
  }
`;

export const OverlayBlockUser = styled.div`
  background-color: var(--blue-card);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 30rem;
  height: 15rem;
  position: absolute;
  top: calc(50% - 7.5rem);
  right: calc(50% - 15rem);
  border: solid 1px var(--light-gray);
  span {
    color: var(--light-gray);
    margin-right: 1rem;
    font-size: 1.2rem;
  }

  div.containerButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5rem;
  }

  button {
    background-color: var(--primary);
    border: none;
    border-radius: 2rem;
    color: var(--light-gray);
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    width: 10rem;
    margin-bottom: 1rem;
  }
`;

export const OverlayNotificateUser = styled.div`
  background-color: var(--blue-card);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 40rem;
  height: 25rem;
  position: absolute;
  top: calc(50% - 12.5rem);
  right: calc(50% - 20rem);
  border: solid 1px var(--light-gray);
  align-items: center;

  span {
    color: var(--light-gray);
    margin-right: 1rem;
    font-size: 1.2rem;
    align-self: flex-start;
  }

  div.containerButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5rem;
  }

  button {
    background-color: var(--primary);
    border: none;
    border-radius: 2rem;
    color: var(--light-gray);
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    width: 10rem;
    margin-bottom: 1rem;
  }
`;

export const TextArea = styled.textarea`
  margin: 1rem 0;
  width: 100%;
  height: 15rem;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 1rem;
`;
