import { useState, useEffect } from 'react';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { Table } from 'components/Table';
import { messages, LanguageTranslated } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

interface IDataTable {
  id: string;
  name: string;
  createDate: string;
  editDate: string;
  value: string;
}

export function ExtractGiftsValues(): JSX.Element {
  const [dataTable, setDataTable] = useState<Array<IDataTable>>(
    [] as Array<IDataTable>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDataTable([
      {
        id: '245g4fdg5d4g54',
        name: 'Rosas',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$500,00',
      },
      {
        id: '245g4fsiandi4',
        name: 'Vinho',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$5650,00',
      },
      {
        id: 'sdip12y8eg1bd01h',
        name: 'Vinho',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$600,00',
      },
      {
        id: 'hd1wh7dg12h',
        name: 'Chocolate',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$5220,00',
      },
      {
        id: 'dwhy12h82h8dh',
        name: 'Rosas',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$400,00',
      },
      {
        id: 'dn21h81h0hd1982h',
        name: 'Rosas',
        createDate: '22/10/2021',
        editDate: '22/10/2021',
        value: 'R$3500,00',
      },
    ]);
  }, []);
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <td>{messages[key].gift}</td>
            <td>{messages[key].createDate}</td>
            <td>{messages[key].editDate}</td>
            <td>{messages[key].value}</td>
          </tr>
        </thead>
        <tbody>
          {dataTable.map(obj => {
            return (
              <tr key={obj.id}>
                <td>{obj.name}</td>
                <td>{obj.createDate}</td>
                <td>{obj.editDate}</td>
                <td>{obj.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
}
