import { ToolBar } from 'components/ToolBar';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { colors } from 'styles/colors';
import { usePageSettings } from 'hooks/pageSettings';
import { useHistory, useLocation } from 'react-router-dom';

import { IMessage } from 'interfaces/pageSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerFilter,
  ContainerInput,
  SearchButton,
  Content,
  CardReport,
  ContainerTypeReport,
  TypeReport,
  ContainerInfos,
  ContainerDetails,
  ButtonDetails,
  Priority,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IReport {
  id: string;
  title: string;
  user: string;
  date: string;
  claimant: string;
  type: string;
  priority: string;
}

interface IFilterOptions {
  new: boolean;
  pending: boolean;
  closed: boolean;
  high: boolean;
  average: boolean;
  low: boolean;
}

interface IProps {
  type: 'reports' | 'ocurences';
}

export function ReportsAndOcurrences(): JSX.Element {
  const [reports, setReports] = useState<Array<IReport>>([] as Array<IReport>);
  const [filter, seFilter] = useState<IFilterOptions>({} as IFilterOptions);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const history = useHistory();
  const location = useLocation<IProps>();

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleSetColor(type: string): string {
    if (type === 'Alta') return colors.danger;
    if (type === 'Média') return colors.orange;
    if (type === 'Baixa') return colors.yellow;
    if (type === 'Nova') return colors.success;
    if (type === 'Fechada') return colors.purple;
    if (type === 'Pendente') return colors.blueReport;

    return colors.black;
  }

  useEffect(() => {
    seFilter({
      average: false,
      closed: false,
      high: false,
      low: false,
      new: false,
      pending: false,
    });

    setReports([
      {
        id: '1',
        title: 'Mensagens inapropriadas',
        type: 'Nova',
        claimant: 'Júlia Alves',
        date: '22/03/2021',
        priority: 'Baixa',
        user: 'Matheus Silva',
      },
      {
        id: '2',
        title: 'Mau comportamento offline',
        type: 'Pendente',
        claimant: 'Renata Gomes',
        date: '28/03/2021',
        priority: 'Alta',
        user: 'João Ferreira',
      },
      {
        id: '3',
        title: 'Parece spam',
        type: 'Fechada',
        claimant: 'Alice Píres',
        date: '25/03/2021',
        priority: 'Média',
        user: 'Paulo Assis',
      },
    ]);
  }, []);

  return (
    <Container>
      <ToolBar
        page={
          location.state.type === 'reports'
            ? messages[key].titleReport
            : messages[key].titleOcurrence
        }
      >
        <ContainerFilter>
          <div className="inputs">
            <div className="status">
              <span>{messages[key].status}</span>
              <ContainerInput>
                <input
                  type="checkbox"
                  name="new"
                  onChange={() => seFilter({ ...filter, new: !filter.new })}
                />
                <span>{messages[key].new}</span>
              </ContainerInput>
              <ContainerInput>
                <input
                  type="checkbox"
                  name="pending"
                  onChange={() =>
                    seFilter({ ...filter, pending: !filter.pending })
                  }
                />
                <span>{messages[key].pending}</span>
              </ContainerInput>
              <ContainerInput>
                <input
                  type="checkbox"
                  name="closed"
                  onChange={() =>
                    seFilter({ ...filter, closed: !filter.closed })
                  }
                />
                <span>{messages[key].closed}</span>
              </ContainerInput>
            </div>
            <div className="urgency">
              <span>{messages[key].urgency}</span>
              <ContainerInput>
                <input
                  type="checkbox"
                  name="high"
                  onChange={() => seFilter({ ...filter, high: !filter.high })}
                />
                <span>{messages[key].high}</span>
              </ContainerInput>

              <ContainerInput>
                <input
                  type="checkbox"
                  name="average"
                  onChange={() =>
                    seFilter({ ...filter, average: !filter.average })
                  }
                />
                <span>{messages[key].average}</span>
              </ContainerInput>

              <ContainerInput>
                <input
                  type="checkbox"
                  name="low"
                  onChange={() => seFilter({ ...filter, low: !filter.low })}
                />
                <span>{messages[key].low}</span>
              </ContainerInput>
            </div>
          </div>
          <SearchButton>
            {messages[key].filter}
            <FaSearch size={20} fill="#FFF" />
          </SearchButton>
        </ContainerFilter>
      </ToolBar>
      <Content>
        {reports.map(obj => {
          return (
            <CardReport key={obj.id}>
              <ContainerTypeReport>
                <TypeReport theme={{ color: handleSetColor(obj.type) }}>
                  {obj.type}
                </TypeReport>
                <p>{obj.title}</p>
              </ContainerTypeReport>
              <ContainerInfos>
                <span>
                  {messages[key].user} {obj.user}
                </span>
                <span>
                  {messages[key].date} {obj.date}
                </span>
                <span>
                  {messages[key].claimant} {obj.claimant}
                </span>
              </ContainerInfos>
              <ContainerDetails>
                <ButtonDetails
                  onClick={() => {
                    if (location.state.type === 'reports')
                      history.push('denuncia-detalhes', {
                        denounced: obj.user,
                        claimant: obj.claimant,
                        date: obj.date,
                        status: obj.type,
                      });
                    else
                      history.push('ocorrencia-detalhes', {
                        denounced: obj.user,
                        claimant: obj.claimant,
                        date: obj.date,
                        status: obj.type,
                      });
                  }}
                >
                  {messages[key].viewDetails}
                </ButtonDetails>
                <Priority theme={{ color: handleSetColor(obj.priority) }}>
                  {obj.priority}
                </Priority>
              </ContainerDetails>
            </CardReport>
          );
        })}
      </Content>
    </Container>
  );
}
