import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  denounced: string;
  claimant: string;
  date: string;
  status: string;
  new: string;
  pending: string;
  closed: string;
  save: string;
  savedChanges: string;
  viewUser: string;
  high: string;
  average: string;
  low: string;
  writeAResponse: string;
  back: string;
  urgency: string;
  user: string;
  type: string;
  account: string;
  balance: string;
  registrationDate: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Ocorrência - detalhes',
    denounced: 'Denunciado:',
    claimant: 'Requerente:',
    closed: 'Fechada',
    date: 'Data:',
    new: 'Nova',
    pending: 'Pendente',
    save: 'Salvar',
    status: 'Status:',
    savedChanges: 'alterações salvas!',
    average: 'Média',
    back: 'Voltar',
    high: 'Alta',
    low: 'Baixa',
    viewUser: 'Ver usuário',
    writeAResponse: 'Escreva uma resposta',
    urgency: 'Urgência:',
    account: 'Conta:',
    balance: 'Saldo:',
    registrationDate: 'Data de cadastro;',
    type: 'Tipo:',
    user: 'Usuário:',
  },
  'en-US': {
    title: 'Report - details',
    denounced: 'Denounced:',
    claimant: 'Claimant:',
    closed: 'Closed',
    date: 'Date:',
    new: 'New',
    pending: 'Pending',
    save: 'Save',
    status: 'Status:',
    savedChanges: 'Saved changes!',
    average: 'Average',
    back: 'Back',
    high: 'High',
    low: 'Low',
    viewUser: 'View User',
    writeAResponse: 'Write a response',
    urgency: 'Urgency:',
    account: 'Conta:',
    balance: 'Balance:',
    registrationDate: 'Registration Date;',
    type: 'Type:',
    user: 'User:',
  },
};
