import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  width: 100vw;
`;

export const ContainerFilter = styled.div`
  background-color: var(--blue-card);
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  width: 90%;
  height: 7rem;
  align-items: center;

  div.inputs {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      margin-right: 1rem;
    }

    div.status,
    div.urgency {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  input {
    width: 1rem;
    height: 1rem;

    :checked {
      color: var(--primary);
    }
  }

  span {
    color: var(--light-gray);
    font-size: 1.2rem;
    margin-left: 0.7rem;
  }
`;

export const ContainerInput = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin: 0.5rem 0;
  margin-right: 1rem;
`;

export const SearchButton = styled.button`
  background-color: var(--primary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  border: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 10rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;

  svg {
    margin-left: 0.5rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, 30rem);
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 3rem;
`;

export const CardReport = styled.div`
  background-color: var(--blue-card);
  display: flex;
  flex-direction: column;
  border-radius: 0 25px 0 25px;
  width: 30rem;
  margin-bottom: 3rem;
`;

export const ContainerTypeReport = styled.div`
  display: flex;
  width: 100%;

  p {
    color: var(--light-gray);
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const TypeReport = styled.span`
  border-radius: 0 0 25px 0;
  padding: 0.3rem 1rem;
  background-color: ${props => props.theme.color};
  justify-self: flex-start;
  color: var(--light-gray);
  font-size: 1.1rem;
  margin-right: 3rem;
  width: 7rem;
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 5rem;
  margin: 0.3rem 0;

  span {
    color: var(--light-gray);
    font-size: 1.1rem;
    text-align: center;
    margin-right: 3rem;
  }
`;

export const ContainerDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.3rem;
  align-items: flex-end;
`;

export const ButtonDetails = styled.button`
  width: 10rem;
  background-color: var(--primary);
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  color: var(--light-gray);
  height: 1.3rem;
  margin-bottom: 5px;
`;

export const Priority = styled.span`
  border-radius: 25px 0 0 0;
  padding: 0.3rem 1rem;
  background-color: ${props => props.theme.color};
  justify-self: flex-start;
  color: var(--light-gray);
  font-size: 1.1rem;
  margin-left: 3rem;
  width: 7rem;
  text-align: end;
`;
