import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Section = styled.div`
  background-color: var(--blue-regular);
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 0.7rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  div.grid {
    display: grid;
    width: 100%;
    justify-content: space-around;
    grid-template-columns: auto auto auto;
    align-items: center;
    @media only screen and (max-width: 900px) {
      grid-template-columns: auto auto;
    }
    @media only screen and (max-width: 600px) {
      grid-template-columns: auto;
    }
    img,
    video {
      width: 315px;
      height: 500px;
    }
    div.item {
      margin-bottom: 0.5rem;
    }
    div.fileItem {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    span {
      color: var(--white);
    }
    span.label {
      padding: 0.5rem;
      font-weight: bold;
    }
    span.information {
      padding: 0.5rem;
    }
  }
  div.userPhotos {
    @media only screen and (max-width: 1200px) {
      grid-template-columns: auto auto;
    }
    @media only screen and (max-width: 800px) {
      grid-template-columns: auto;
    }
  }
  div.userDocuments {
    grid-template-columns: auto auto;
    @media only screen and (max-width: 1200px) {
      grid-template-columns: auto;
    }
  }
`;
export const Details = styled.div`
  background-color: var(--blue-dark);
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  div.buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;
export const Button = styled.button`
  border-radius: 2rem;
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: var(--light-gray);
  margin-bottom: 1rem;
  border: none;
  width: 12rem;
`;
