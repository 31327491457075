import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  email: string;
  password: string;
  buttonSignIn: string;
  forgotPassword: string;
  selectLanguage: string;
  slogan: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    slogan: 'Colocar aqui uma frase de efeito ou slogan',
    email: 'Email',
    password: 'Senha',
    buttonSignIn: 'Entrar',
    forgotPassword: 'Esqueci a senha',
    selectLanguage: 'Selecione o idioma',
  },
  'en-US': {
    slogan: 'Put a catchphrase or slogan here',
    email: 'Email',
    password: 'Password',
    buttonSignIn: 'Sign in',
    forgotPassword: 'Forgot password',
    selectLanguage: 'Select language',
  },
};
