import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  gift: string;
  createDate: string;
  editDate: string;
  value: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    gift: 'Presente',
    createDate: 'Data de criação',
    editDate: 'Data de edição',
    value: 'Valor',
  },
  'en-US': {
    gift: 'Gift',
    createDate: 'Create date',
    editDate: 'Edit date',
    value: 'Value',
  },
};
