import { ToolBar } from 'components/ToolBar';
import { useEffect, useState } from 'react';
import { usePageSettings } from 'hooks/pageSettings';

import { FaSearch } from 'react-icons/fa';
import InsertImage from 'assets/images/svg/insert_svg.svg';

import { IMessage } from 'interfaces/pageSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerInputSearch,
  InputSearch,
  AddButton,
  Content,
  ButtonCard,
  ContentCard,
  GiftCard,
  ImageCard,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;
interface IGift {
  id: string;
  name: string;
  value: number;
}

export function Gifts(): JSX.Element {
  const [gifts, setGifts] = useState<Array<IGift>>([] as Array<IGift>);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    (() => {
      const data: Array<IGift> = [
        {
          id: 'ihsnd117829h3bqasdwe',
          name: 'Chocolate',
          value: 5,
        },
        {
          id: 'ihsnd11asda29h3bqsada',
          name: 'Flores',
          value: 15,
        },
        {
          id: 'ihssadasqd117829h3bqas',
          name: 'Vinho',
          value: 100,
        },
      ];
      setGifts(data);
    })();
  });
  return (
    <Container>
      <ToolBar page={messages[key].title}>
        <ContainerInputSearch>
          <div className="search">
            <InputSearch
              placeholder={messages[key].searchPlaceholder}
              name="searchInput"
            />
            <FaSearch size={30} fill="#FA6379" cursor="pointer" />
          </div>
          <div className="addButton">
            <AddButton>{messages[key].addButton}</AddButton>
          </div>
        </ContainerInputSearch>
      </ToolBar>
      <div className="all">
        <Content>
          {gifts.map(item => (
            <GiftCard key={item.id}>
              <ImageCard>
                <img src={InsertImage} alt={item.name} />
              </ImageCard>
              <ContentCard>
                <div className="information">
                  <span className="name">{item.name}</span>
                  <span className="value">{item.value}</span>
                </div>
                <div className="buttons">
                  <ButtonCard>{messages[key].updateButton}</ButtonCard>
                  <ButtonCard>{messages[key].removeButton}</ButtonCard>
                </div>
              </ContentCard>
            </GiftCard>
          ))}
        </Content>
      </div>
    </Container>
  );
}
