import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  titleReport: string;
  titleOcurrence: string;
  new: string;
  pending: string;
  closed: string;
  high: string;
  average: string;
  low: string;
  filter: string;
  viewDetails: string;
  claimant: string;
  user: string;
  date: string;
  urgency: string;
  status: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    average: 'Média',
    claimant: 'Requerente:',
    closed: 'Fechada',
    date: 'Data:',
    filter: 'Filtrar',
    high: 'Alta',
    low: 'Baixa',
    new: 'Nova',
    pending: 'Pendente',
    titleReport: 'Denúncias',
    user: 'Usuário:',
    viewDetails: 'Ver detalhes',
    titleOcurrence: 'Ocorrências',
    status: 'Status:',
    urgency: 'Urgência:',
  },
  'en-US': {
    average: 'Average',
    claimant: 'Claimant:',
    closed: 'Closed',
    date: 'Date:',
    filter: 'Filter',
    high: 'High',
    low: 'Low',
    new: 'New',
    pending: 'Pending',
    titleReport: 'Reports',
    user: 'User:',
    viewDetails: 'View details',
    titleOcurrence: 'Ocurrences',
    status: 'Status:',
    urgency: 'Urgency:',
  },
};
