import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  id: string;
  user: string;
  email: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    id: 'ID',
    user: 'Usuário',
    email: 'Email',
  },
  'en-US': {
    id: 'ID',
    user: 'User',
    email: 'Email',
  },
};
