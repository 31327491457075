import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  age: string;
  statusActive: string;
  statusInactive: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    age: 'anos',
    statusActive: 'Ativo',
    statusInactive: 'Inativo',
  },
  'en-US': {
    age: 'years',
    statusActive: 'Active',
    statusInactive: 'Inactive',
  },
};
