import styled, { css } from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { Button } from '../Button';

export const Menu = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  left: 0rem;
  width: 25rem;
  top: 0rem;
  bottom: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0%;
  transition: all 0.2s linear;

  border: 1px solid var(--graphite);
  box-sizing: border-box;

  background: var(--secondary);

  div.divider {
    width: 100%;
  }

  ${props => {
    if (!props.theme.slide) return css``;
    return css`
      transform: translateX(-100%);
    `;
  }}
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--secondary);
  border-right: 0.0625rem solid var(--primary);
`;

export const AdmContainer = styled.div`
  position: relative;
  width: 100%;
  height: 8.5rem;
  display: flex;
  align-items: center;
  padding-left: 10%;
  margin-top: 2rem;

  span {
    color: var(--white);
    font-size: 1.8rem;
    line-height: auto;
    margin-left: 1.5rem;
    cursor: context-menu;

    @media (max-width: 900px) {
      font-size: 1.5rem;
    }
  }
`;

export const Overlay = styled.div`
  background-color: var(--white);
  width: 25rem;
  height: 5rem;
  position: absolute;
  top: 5.6rem;
  left: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${props => props.theme.display};
`;

export const ButtonLogOut = styled.button`
  background-color: transparent;
  width: 100%;
  color: var(--secondary);
  font-size: 1.3rem;
  border: none;
  font-weight: 400;
`;

export const MenuButton = styled.div`
  width: 100%;
  height: 5.625rem;
`;
export const IconButton = styled.button`
  background-color: red;
  border: 0;
  align-items: center;
  position: initial;
  width: 3rem;
  height: 2.3125rem;
  margin-left: 2.375rem;
  margin-top: 1.9rem;
  font-size: 1.5rem;
  box-shadow: 'none';
`;

export const MenuIconButton = styled.button`
  background-color: var(--blue-aqua);
  width: 100%;
  height: 6rem;
  padding: 1.5rem;
  font-size: 1.5rem;
  border-radius: 1rem 2.8rem 2.8rem 2.8rem;
  border: 1px solid var(--white);
  box-sizing: border-box;

  span {
    font-size: 1.5rem;
    color: var(--white);
    @media (max-width: 900px) {
      font-size: 1.3rem;
    }
  }
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MenuIcon = styled.div`
  position: relative;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding-left: 10%;
  cursor: pointer;

  @media (min-width: 600px) {
    :hover {
      background-color: var(--dark-gray);
      transition: 300ms;
    }
  }
`;

export const HiddenMenuIcon = styled(MenuIcon)`
  display: ${props => props.theme.display};
  background-color: var(--blue-light);
  padding-left: 0;
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.2rem;
  }

  @media (min-width: 600px) {
    :hover {
      background-color: #3d6ca6;
      transition: 300ms;
    }
  }
`;

export const ButtonMenu = styled(Button)`
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  border-radius: 0;
  height: 100%;
  margin-bottom: 0;
  width: auto;
  span {
    color: var(--white);
    font-size: 1.5rem;
    line-height: auto;
    @media (max-width: 900px) {
      font-size: 1.3rem;
    }
  }
`;

export const HiddenButtonMenu = styled(ButtonMenu)``;

export const ListMenu = styled.div`
  position: relative;
  margin-top: 3.9rem;
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding-left: 2rem;
  padding-top: 1rem;
  margin-top: 1rem;
  justify-content: center;

  img {
    width: 70%;
    fill: var(--white);
  }
`;

export const HiddenCloseButton = styled(IoIosArrowBack)`
  /* position: absolute;
  left: 23rem;
  top: 1rem; */
  fill: var(--primary);
  cursor: pointer;
`;
