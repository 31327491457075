import { useEffect, useState } from 'react';
import { ToolBar } from 'components/ToolBar';
import { useToast } from 'hooks/toast';
import { useLocation } from 'react-router-dom';
import { usePageSettings } from 'hooks/pageSettings';

import { IMessage } from 'interfaces/pageSettings';
import { PerfilExpanded } from 'components/PerfilExpanded';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerDatails,
  Content,
  ContainerDenouncedProfile,
  ContainerReport,
  DenouncedImage,
  Chat,
  ContainerMessageMe,
  ContainerMessageUser,
  ContainerMessages,
  ContainerOptions,
  ContainerChangeStatus,
  ContainerActions,
  Actions,
  OverlayBlockUser,
  TextArea,
  OverlayNotificateUser,
  ContainerInput,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IReportData {
  denounced: string;
  claimant: string;
  date: string;
  status: string;
}

interface IUserMessages {
  id: string;
  message: string;
  whoSent: 'me' | 'user';
  hour: string;
}

export function ReportDetails(): JSX.Element {
  const [reportStatus, setReportStatus] = useState('new');
  const [showOverlayBlock, setShowOverlayBlock] = useState(false);
  const [userToBlock, setUserToBlock] = useState('');
  const userImages = [1, 2, 3, 4];
  const [currentImage, setCurrentImage] = useState(0);
  const [showOverlayNotificateUser, setShowOverlayNotificateUser] = useState(
    false,
  );
  const [userMessages, setUserMessages] = useState<Array<IUserMessages>>(
    [] as Array<IUserMessages>,
  );
  const { addSuccess } = useToast();
  const location = useLocation<IReportData>();
  const { denounced, claimant, date, status } = location.state;
  const [viewUser, setViewUser] = useState(false);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleUserToBlock(user: string) {
    setUserToBlock(user);
    setShowOverlayBlock(true);
  }

  function handleBlockUser() {
    setShowOverlayBlock(false);
    addSuccess(`${messages[key].userBlocked}`);
  }

  function handleNotificateUser() {
    setShowOverlayNotificateUser(false);
    addSuccess(`${messages[key].messageSent}`);
  }

  function handleSlideImages(direction: 'left' | 'right') {
    if (direction === 'left') {
      if (currentImage > 0) setCurrentImage(currentImage - 1);
    }
    if (direction === 'right') {
      if (currentImage < userImages.length - 1)
        setCurrentImage(currentImage + 1);
    }
  }
  useEffect(() => {
    setReportStatus(location.state.status);
  }, [location]);

  useEffect(() => {
    setUserMessages([
      {
        id: '1',
        message: 'Olá',
        whoSent: 'user',
        hour: '6:59h',
      },
      {
        id: '2',
        message: 'Bom dia!',
        whoSent: 'user',
        hour: '7h',
      },
      {
        id: '3',
        message: 'Bom dia!',
        whoSent: 'me',
        hour: '7:01h',
      },
      {
        id: '4',
        message: 'Bom dia!',
        whoSent: 'me',
        hour: '7:01h',
      },
    ]);
  }, []);

  function handleSaveReportStatus() {
    const data = { reportStatus };
    addSuccess(`${messages[key].savedStatus} ${data.reportStatus}`);
    location.state.status = reportStatus;
  }

  function handleViewUser(display: boolean) {
    setViewUser(display);
  }

  return (
    <Container>
      <ToolBar page={messages[key].title}>
        <ContainerDatails>
          <div>
            <span className="user">
              {messages[key].denounced} {denounced}
            </span>
            <button type="button" onClick={() => handleViewUser(true)}>
              {messages[key].viewUser}
            </button>
          </div>
          <span>
            {messages[key].date} {date}
          </span>
          <span>
            {messages[key].claimant} {claimant}
          </span>
          <span>
            {messages[key].status} {status}
          </span>
        </ContainerDatails>
      </ToolBar>
      <Content>
        <ContainerReport>
          <ContainerDenouncedProfile>
            <span>{messages[key].denouncedProfile}</span>
            <DenouncedImage>
              <div className="image">
                <h1>{userImages[currentImage]}</h1>
              </div>
              <div className="controll">
                <button type="button" onClick={() => handleSlideImages('left')}>
                  {'<'}
                </button>
                <span>
                  {currentImage + 1} {messages[key].for} {userImages.length}
                </span>
                <button
                  type="button"
                  onClick={() => handleSlideImages('right')}
                >
                  {'>'}
                </button>
              </div>
            </DenouncedImage>
            <p>
              {messages[key].age} 52 {messages[key].years}
            </p>
            <p>
              {messages[key].description} 1,79m. Gosto de babys loiras com o pé
              bonito.
            </p>
          </ContainerDenouncedProfile>
          <ContainerMessages>
            <span>{messages[key].messages}</span>
            <Chat>
              {userMessages.map(obj => {
                return obj.whoSent === 'user' ? (
                  <ContainerMessageUser key={obj.id}>
                    <div>
                      <p>{obj.message}</p>
                      <span>{obj.hour}</span>
                    </div>
                  </ContainerMessageUser>
                ) : (
                  <ContainerMessageMe key={obj.id}>
                    <div>
                      <p>{obj.message}</p>
                      <span>{obj.hour}</span>
                    </div>
                  </ContainerMessageMe>
                );
              })}
            </Chat>
          </ContainerMessages>
        </ContainerReport>
        <ContainerOptions>
          <ContainerChangeStatus>
            <span className="option">{messages[key].status} </span>
            <ContainerInput>
              <input
                id="new"
                type="radio"
                name="status"
                value="new"
                defaultChecked
                onChange={e => setReportStatus(e.target.value)}
              />
              <label htmlFor="new">{messages[key].new}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="pending"
                type="radio"
                name="status"
                value="pending"
                onChange={e => setReportStatus(e.target.value)}
              />
              <label htmlFor="pending">{messages[key].pending}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="closed"
                type="radio"
                name="status"
                value="closed"
                onChange={e => setReportStatus(e.target.value)}
              />

              <label htmlFor="closed">{messages[key].closed}</label>
            </ContainerInput>

            <button type="button" onClick={() => handleSaveReportStatus()}>
              {messages[key].save}
            </button>
          </ContainerChangeStatus>
          <ContainerActions>
            <span>{messages[key].actions} </span>
            <Actions>
              <button
                type="button"
                onClick={() => setShowOverlayNotificateUser(true)}
              >
                {messages[key].notificateDenounced}
              </button>
              <button
                type="button"
                onClick={() => handleUserToBlock(denounced)}
              >
                {messages[key].blockDenounced}
              </button>
              <button
                type="button"
                onClick={() => setShowOverlayNotificateUser(true)}
              >
                {messages[key].notificateClaimant}
              </button>
              <button type="button" onClick={() => handleUserToBlock(claimant)}>
                {messages[key].blockClaimant}
              </button>
            </Actions>
          </ContainerActions>
        </ContainerOptions>
      </Content>
      {showOverlayBlock && (
        <OverlayBlockUser>
          <span>
            {messages[key].wantBlockUser} {userToBlock}?
          </span>
          <div className="containerButtons">
            <button type="button" onClick={() => handleBlockUser()}>
              {messages[key].yes}
            </button>
            <button type="button" onClick={() => setShowOverlayBlock(false)}>
              {messages[key].no}
            </button>
          </div>
        </OverlayBlockUser>
      )}
      {showOverlayNotificateUser && (
        <OverlayNotificateUser>
          <span>{messages[key].sendMessage}</span>
          <TextArea />
          <div className="containerButtons">
            <button type="button" onClick={() => handleNotificateUser()}>
              {messages[key].send}
            </button>
            <button
              type="button"
              onClick={() => setShowOverlayNotificateUser(false)}
            >
              {messages[key].cancel}
            </button>
          </div>
        </OverlayNotificateUser>
      )}
      {viewUser && (
        <PerfilExpanded
          nick={denounced}
          age="52"
          city="Juiz de Fora"
          country="Brasil"
          status="Ativo"
          state="Minas Gerais"
          key="1"
          handleClose={handleViewUser}
        />
      )}
    </Container>
  );
}
