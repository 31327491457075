import { useEffect, useState } from 'react';
import { ToolBar } from 'components/ToolBar';
import { FaSearch } from 'react-icons/fa';

import { countries } from 'utils/countries';
import { brazilianStates } from 'utils/brazilianStates';
import { usePageSettings } from 'hooks/pageSettings';

import { IMessage } from 'interfaces/pageSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Button,
  CloseButton,
  Container,
  ContainerInputSearch,
  ContainerInputSearchAdvanced,
  Content,
  InputAdvancedFilter,
  InputSearch,
  SearchButton,
  SearchFilter,
  Select,
  Table,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUser {
  name: string;
  city: string;
  state: string;
  country: string;
  email: string;
  registryDate: string;
  type: string;
  age: string;
  gender: string;
  currency: string;
  value: string;
}

interface IFilter extends IUser {
  minValue: string;
  maxValue: string;
}

export function ActiveUsers(): JSX.Element {
  const [users, setUers] = useState<Array<IUser>>([] as Array<IUser>);
  const [usersFiltered, setUsersFiltered] = useState<Array<IUser>>(
    [] as Array<IUser>,
  );
  const [showAdvancedFIlter, setShowAdvancedFilter] = useState(false);
  const [filterData, setFIlterData] = useState<IFilter>({
    name: '',
    city: '',
    state: '',
    country: '',
    email: '',
    registryDate: '',
    type: '',
    age: '',
    gender: '',
    currency: '',
    minValue: '',
    maxValue: '',
    value: '',
  });
  const [dateMask, setDateMask] = useState<string>('');

  const [countriesOfWorld, setCountriesOfWorld] = useState<Array<JSX.Element>>(
    [],
  );
  const [citiesOfStates, setCitiesOfStates] = useState<Array<string>>([]);
  const [countryStates, setCountryStates] = useState<Array<JSX.Element>>([]);
  const [cities, setCities] = useState<Array<JSX.Element>>([]);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setUers([
      {
        name: 'Thiago ',
        city: 'Juiz de fora',
        state: 'Minas Gerais',
        country: 'Brasil',
        email: 'example@mail.com',
        registryDate: '12/12/2020',
        type: 'Daddy',
        age: '49',
        gender: 'M',
        currency: 'BRL',
        value: '500',
      },
    ]);
  }, []);

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  useEffect(() => {
    setDateMask('99/99/9999');
    const arrayAux: Array<JSX.Element> = [
      <option value="" key="selectCountry">
        Selecione o país
      </option>,
    ];

    countries.map(obj => {
      return arrayAux.push(
        <option value={obj.nome_pais} key={obj.sigla}>
          {obj.nome_pais}
        </option>,
      );
    });

    setCountriesOfWorld(arrayAux);
  }, []);

  useEffect(() => {
    const arrayAux: Array<JSX.Element> = [
      <option value="" key="selectState">
        Selecione o estado
      </option>,
    ];

    if (filterData.country === 'Brasil')
      brazilianStates.map(state => {
        return arrayAux.push(
          <option value={state.nome} key={state.sigla}>
            {state.nome}
          </option>,
        );
      });

    setCountryStates(arrayAux);
  }, [filterData.country]);

  useEffect(() => {
    const arrayCities: Array<JSX.Element> = [
      <option value="" key="selectCity">
        Selecione a cidade
      </option>,
    ];

    for (let i = 0; i < brazilianStates.length; i += 1) {
      if (brazilianStates[i].nome === filterData.state)
        setCitiesOfStates(brazilianStates[i].cidades);
    }

    if (citiesOfStates.length) {
      citiesOfStates.map(city => {
        return arrayCities.push(
          <option value={city} key={city}>
            {city}
          </option>,
        );
      });
    }
    setCities(arrayCities);
  }, [filterData.state, citiesOfStates]);

  function searchUsers() {
    if (filterData.name?.length)
      setUsersFiltered(
        users.filter(obj =>
          obj.name.toLowerCase().includes(filterData.name.toLowerCase()),
        ),
      );

    if (!filterData.name.length) setUsersFiltered(users);
  }

  return (
    <Container>
      <ToolBar page={messages[key].subtitle}>
        <SearchFilter>
          <ContainerInputSearch
            theme={{ display: !showAdvancedFIlter ? 'flex' : 'none' }}
          >
            <div>
              <InputSearch
                placeholder={messages[key].searchPlaceholder}
                name="searchInput"
                onChange={e =>
                  setFIlterData({ ...filterData, name: e.target.value })
                }
              />
              <FaSearch
                size={30}
                fill="#FA6379"
                cursor="pointer"
                onClick={() => searchUsers()}
              />
            </div>
            <Button onClick={() => setShowAdvancedFilter(true)}>
              {messages[key].advancedFilter}
            </Button>
          </ContainerInputSearch>

          <ContainerInputSearchAdvanced
            theme={{ display: showAdvancedFIlter ? 'flex' : 'none' }}
          >
            <CloseButton
              size={30}
              onClick={() => setShowAdvancedFilter(false)}
            />
            <div>
              <InputAdvancedFilter
                placeholder={messages[key].name}
                name="nome"
                value={filterData.name}
                onChange={e =>
                  setFIlterData({ ...filterData, name: e.target.value })
                }
              />
            </div>
            <div />

            <div />

            <div>
              <Select
                placeholder={messages[key].country}
                name="país"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, country: e.target.value })
                }
              >
                {countriesOfWorld}
              </Select>
            </div>

            <div>
              <Select
                placeholder={messages[key].city}
                name="estado"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, state: e.target.value })
                }
              >
                {countryStates}
              </Select>
            </div>

            <div>
              <Select
                placeholder={messages[key].city}
                name="cidade"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, city: e.target.value })
                }
              >
                {cities}
              </Select>
            </div>
            <div>
              <InputAdvancedFilter
                placeholder={messages[key].date}
                name="data"
                type="masked"
                mask={dateMask}
                value={filterData.registryDate}
                onChange={e =>
                  setFIlterData({ ...filterData, registryDate: e.target.value })
                }
              />
            </div>
            <div>
              <Select
                placeholder={messages[key].type}
                name="tipo"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, type: e.target.value })
                }
              >
                <option value="">{messages[key].type}</option>
                <option value="Daddy">Daddy</option>
                <option value="Babby">Babby</option>
              </Select>
            </div>
            <div>
              <InputAdvancedFilter
                placeholder={messages[key].age}
                name="idade"
                type="number"
                value={filterData.age}
                onChange={e =>
                  setFIlterData({ ...filterData, age: e.target.value })
                }
              />
            </div>
            <div>
              <Select
                placeholder={messages[key].gender}
                name="Sexo"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, gender: e.target.value })
                }
              >
                <option value="">{messages[key].gender}</option>
                <option value="M">M</option>
                <option value="F">F</option>
              </Select>
            </div>
            <div>
              <Select
                placeholder="moeda"
                name="moeda"
                defaultValue=""
                onChange={e =>
                  setFIlterData({ ...filterData, currency: e.target.value })
                }
              >
                <option value="">Moeda</option>
                <option value="BRL">BRL</option>
                <option value="USD">USD</option>
              </Select>
            </div>
            <div>
              <InputAdvancedFilter
                placeholder={messages[key].minValue}
                name="valor mínimo"
                value={filterData.minValue}
                type="currency"
                prefix="R$ "
                onValueChange={value => {
                  if (value) {
                    setFIlterData({ ...filterData, minValue: value });
                  } else {
                    setFIlterData({ ...filterData, minValue: '' });
                  }
                }}
              />
            </div>
            <div>
              <InputAdvancedFilter
                placeholder={messages[key].maxValue}
                name="valor máximo"
                value={filterData.maxValue}
                type="currency"
                prefix="R$ "
                onValueChange={value => {
                  if (value) {
                    setFIlterData({ ...filterData, maxValue: value });
                  } else {
                    setFIlterData({ ...filterData, maxValue: '' });
                  }
                }}
              />
            </div>
            <SearchButton>
              {messages[key].search} <FaSearch size={20} fill="#FFF" />
            </SearchButton>
          </ContainerInputSearchAdvanced>
        </SearchFilter>
      </ToolBar>
      <Content>
        <Table>
          <thead>
            <tr>
              <td>{messages[key].name}</td>
              <td>{messages[key].city}</td>
              <td>{messages[key].state}</td>
              <td>{messages[key].country}</td>
              <td>{messages[key].email}</td>
              <td>{messages[key].date}</td>
              <td>{messages[key].type}</td>
              <td>{messages[key].age}</td>
              <td>{messages[key].gender}</td>
              <td>$</td>
              <td>{messages[key].value}</td>
            </tr>
          </thead>
          <tbody>
            {usersFiltered.map((obj, index) => {
              return (
                <tr key={index.toString()}>
                  <td>{obj.name}</td>
                  <td>{obj.city}</td>
                  <td>{obj.state}</td>
                  <td>{obj.country}</td>
                  <td>{obj.email}</td>
                  <td>{obj.registryDate}</td>
                  <td>{obj.type}</td>
                  <td>{obj.age}</td>
                  <td>{obj.gender}</td>
                  <td>{obj.currency}</td>
                  <td>R$ {obj.value}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Content>
    </Container>
  );
}
