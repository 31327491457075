import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  searchPlaceholder: string;
  name: string;
  country: string;
  state: string;
  city: string;
  date: string;
  type: string;
  age: string;
  email: string;
  gender: string;
  currency: string;
  minValue: string;
  maxValue: string;
  search: string;
  advancedFilter: string;
  value: string;
  subtitle: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Painel administrativo',
    subtitle: 'Usuários ativos',
    searchPlaceholder: 'Digite um nome...',
    age: 'Idade',
    city: 'Cidade',
    country: 'País',
    currency: 'Moeda',
    date: 'Data',
    gender: 'Sexo',
    email: 'Email',
    maxValue: 'Valor máximo',
    minValue: 'Valor mínimo',
    name: 'Nome',
    search: 'Pesquisar',
    state: 'Estado',
    type: 'Tipo',
    advancedFilter: 'Filtro avançado',
    value: 'Valor',
  },
  'en-US': {
    title: 'Administrative panel',
    subtitle: 'Active users',
    searchPlaceholder: 'put a name...',
    age: 'Age',
    city: 'City',
    country: 'Country',
    currency: 'Currency',
    date: 'Date',
    email: 'Email',
    gender: 'Gender',
    maxValue: 'Maximum value',
    minValue: 'Minimum value',
    name: 'Name',
    search: 'Search',
    state: 'State',
    type: 'Type',
    advancedFilter: 'Advanced filter',
    value: 'Value',
  },
};
