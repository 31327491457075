import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  width: 100vw;
  height: auto;
`;

export const Content = styled.div`
  width: 100%;
  overflow: scroll;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const SearchFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

export const ContainerInputSearch = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }

  div {
    width: 50%;
    background-color: var(--white);
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;

    @media (max-width: 900px) {
      width: 40%;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const InputSearch = styled.input`
  width: 90%;
  background-color: var(--white);
  border-radius: 2rem;
  color: var(--graphite);
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  height: 100%;
  border: none;
  @media (max-width: 900px) {
    width: 80%;
    font-size: 1rem;
  }
`;

export const SearchButton = styled.button`
  background-color: var(--primary);
  border-radius: 2rem;
  padding: 0.5rem;
  color: var(--white);
  border: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 13rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 1rem;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  min-height: 90vh;
  overflow: auto;
`;

export const ContainerCard = styled.div`
  border-radius: 20px;
  padding: 1rem;
  background-color: var(--blue-card);
  display: flex;
  margin-top: 1rem;
  height: 9rem;

  @media (max-width: 950px) {
    flex-direction: column;
    height: auto;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 20rem;

  @media (max-width: 950px) {
    margin-right: 0;
  }

  span {
    font-size: 1.2rem;
    color: var(--light-gray);
    line-height: 1.7rem;

    @media (max-width: 950px) {
      line-height: 2rem;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 17rem;
  padding: 0 0.7rem;

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const CardButton = styled.button`
  border-radius: 2rem;
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: var(--light-gray);
  margin-bottom: 1rem;
  border: none;
  width: 12rem;
`;
