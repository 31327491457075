import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  day: string;
  month: string;
  year: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  monthlyPayment: string;
  creditPurchase: string;
  redemptionRequests: string;
  inactiveCustomers: string;
  giftsValues: string;
  sendCredits: string;
  extractsOfDay: string;
  exportPdf: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Extratos',
    day: 'Dia:',
    month: 'Mês:',
    year: 'Ano:',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    creditPurchase: 'Compra de crédito',
    giftsValues: 'Valores dos presentes virtuais',
    inactiveCustomers: 'Clientes Inativos não pagadores de mesalidade',
    monthlyPayment: 'Pagamento de mensalidade',
    redemptionRequests: 'Solicitações de resgate',
    sendCredits: 'Envio de crédito às babys',
    extractsOfDay: 'Extratos do dia:',
    exportPdf: 'Exportar PDF',
  },
  'en-US': {
    title: 'Extracts',
    day: 'Day:',
    month: 'Month:',
    year: 'Year:',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'Juny',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    creditPurchase: 'Credit purchase',
    giftsValues: 'Value of virtual gifts',
    inactiveCustomers: 'Inactive Customers who do not pay the monthly fee',
    monthlyPayment: 'Monthly payment',
    redemptionRequests: 'Redemption requests',
    sendCredits: 'Send credits to babies',
    extractsOfDay: 'Extracts of the day:',
    exportPdf: 'Export PDF',
  },
};
