import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  id: string;
  user: string;
  date: string;
  value: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    id: 'ID',
    user: 'Usuário',
    date: 'Data do pagamento',
    value: 'Valor',
  },
  'en-US': {
    id: 'ID',
    user: 'User',
    date: 'Date of payment',
    value: 'Value',
  },
};
