import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { Button } from 'components/Button';

export const Container = styled(DefaultPage)`
  div.all {
    display: flex;
    justify-content: center;
    background-color: var(--blue-dark);
  }
`;
export const Content = styled.div`
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  @media (max-width: 1550px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 1020px) {
    grid-template-columns: auto;
  }
  justify-content: space-around;
  width: 80%;
  background-color: var(--blue-regular);
`;
export const GiftCard = styled.div`
  background-color: var(--blue-dark);
  border-radius: 1rem;
  display: flex;
  margin: 1rem 0;
`;
export const ImageCard = styled.div`
  padding: 1rem 2rem 1rem 1rem;
  img {
    border-radius: 1rem;
    width: 150px;
    height: 100px;
  }
`;
export const ContentCard = styled.div`
  padding: 1rem 2rem 0 0;
  div.information {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    justify-content: center;
  }
  div.buttons {
    display: flex;
    justify-content: space-between;
    width: 110%;
  }

  span {
    color: var(--white);
  }
`;
export const ButtonCard = styled(Button).attrs(() => ({ size: 'small' }))`
  border-radius: 30px;
  width: 5rem;
`;
export const AddButton = styled(Button).attrs(() => ({ size: 'regular' }))`
  border-radius: 30px;
`;

export const ContainerInputSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }
  div.addButton {
    width: 10rem;
  }
  div.search {
    width: 50%;
    background-color: var(--white);
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    margin: 1rem 0;

    @media (max-width: 900px) {
      width: 40%;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export const InputSearch = styled.input`
  width: 90%;
  background-color: var(--white);
  border-radius: 2rem;
  color: var(--graphite);
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  height: 100%;
  border: none;
  @media (max-width: 900px) {
    width: 80%;
    font-size: 1rem;
  }
`;
