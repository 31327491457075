import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  width: 100vw;
`;

export const ContainerDatails = styled.div`
  width: 60%;
  background-color: var(--blue-card);
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 1rem;
  padding-bottom: 0;

  @media (max-width: 1400px) {
    width: 90%;
  }

  div {
    width: 50%;
    margin-bottom: 1rem;

    button {
      background-color: var(--primary);
      padding: 0.5rem 1rem;
      color: var(--light-gray);
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
    }
  }

  span {
    color: var(--light-gray);
    font-size: 1.3rem;
    width: 50%;
  }

  span.user {
    margin-right: 1rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem;
  align-items: center;

  @media (max-width: 1400px) {
    padding: 1rem;
  }
`;

export const ContainerOptions = styled.div`
  width: 70%;
  background-color: var(--blue-card);
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 1rem;
  align-items: center;

  button {
    background-color: var(--primary);
    border: none;
    border-radius: 2rem;
    color: var(--light-gray);
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    width: 10rem;
    margin-top: 1rem;
  }

  span.option {
    color: var(--light-gray);
    font-size: 1.4rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }

  div.status,
  div.urgency {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;

  label {
    font-size: 1.2rem;
    color: var(--light-gray);
    margin-right: 1rem;
  }
  input {
    margin-right: 0.5rem;
  }
`;

export const ContainerChangeStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: center;
    border: solid 1px red;
  }

  span {
    color: var(--light-gray);
    margin-right: 1rem;
    font-size: 1.2rem;
  }
`;

export const ContainerChat = styled.div`
  width: 70%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: var(--blue-card);
  border-radius: 20px;
  margin-top: 1rem;
`;

export const Chat = styled.div`
  width: 100%;
  height: 20rem;
  padding: 1rem;
  background-color: var(--light-gray);
  border-radius: 5px;
  overflow-y: auto;
`;

export const ContainerMessageMe = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  div {
    background-color: var(--primary);
    padding: 0.5rem 0.4rem 0.5rem 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 10px;
  }

  p {
    color: var(--light-gray);
    font-size: 1rem;
  }

  span {
    color: var(--black);
    font-size: 0.6rem;
    margin-left: 0.3rem;
  }
`;

export const ContainerMessageUser = styled.div`
  display: flex;
  margin-bottom: 1rem;

  div {
    background-color: var(--secondary);
    padding: 0.5rem 0.4rem 0.5rem 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 10px;
  }

  p {
    color: var(--light-gray);
    font-size: 1rem;
  }

  span {
    color: var(--gray);
    font-size: 0.6rem;
    margin-left: 0.3rem;
  }
`;

export const ContainerWriteMessage = styled.div`
  width: 70%;
  border-radius: 30rem;
  margin-top: 0.7rem;
  display: flex;
  height: 7rem;
`;

export const ButtonAttachment = styled.label`
  background-color: var(--primary);
  height: 100%;
  width: 4rem;
  border-radius: 30px 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

export const ButtonSendMessage = styled.button`
  border-radius: 0 30px 30px 0;
  background-color: var(--primary);
  height: 100%;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

export const WriteMessage = styled.textarea`
  flex: 1;
  padding: 2rem;
  font-size: 1.3rem;
  color: var(--graphite);
  border: none;
  background-color: var(--gray);
  max-height: 100%;
  min-height: 100%;

  ::placeholder {
    color: var(--graphite);
    opacity: 0.8;
  }
`;

export const InputFile = styled.input`
  display: none;
`;
