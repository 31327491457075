import { useEffect, useState } from 'react';
import { ToolBar } from 'components/ToolBar';
import { useLocation } from 'react-router-dom';
import { IoSend } from 'react-icons/io5';
import { BsPaperclip } from 'react-icons/bs';
import { usePageSettings } from 'hooks/pageSettings';
import { useToast } from 'hooks/toast';

import { IMessage } from 'interfaces/pageSettings';
import { PerfilExpanded } from 'components/PerfilExpanded';
import { messages, LanguageTranslated } from './translate';

import {
  ButtonAttachment,
  ButtonSendMessage,
  Chat,
  Container,
  ContainerChat,
  ContainerDatails,
  ContainerInput,
  ContainerMessageMe,
  ContainerMessageUser,
  ContainerOptions,
  ContainerWriteMessage,
  Content,
  InputFile,
  WriteMessage,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IReportData {
  denounced: string;
  claimant: string;
  date: string;
  status: string;
}

interface IMessages {
  id: string;
  message: string;
  whoSent: 'me' | 'user';
  hour: string;
}

export function OcurrenceDetails(): JSX.Element {
  const location = useLocation<IReportData>();
  const { denounced, date } = location.state;
  const [ocurrenceStatus, setOcurrenceStatus] = useState(location.state.status);
  const [statusSelected, setStatusSelected] = useState('');
  const [ocurrenceUrgency, setOcurrenceUrgency] = useState('');
  const [urgencySelected, setUrgencySelected] = useState('');
  const [viewUser, setViewUser] = useState(false);
  const [messageWrited, setMessageWrited] = useState('');
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  const { addSuccess } = useToast();
  const [userMessages, setUserMessages] = useState<Array<IMessages>>(
    [] as Array<IMessages>,
  );

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setUserMessages([
      {
        id: '1',
        hour: '7h',
        message: 'blá blá blá',
        whoSent: 'user',
      },
      {
        id: '2',
        hour: '7:32h',
        message: 'blá blá blá blá',
        whoSent: 'me',
      },
    ]);
  }, []);

  function handleGetCurrentTime() {
    const data = new Date();
    return `${data.getHours()}:${data.getMinutes()}h`;
  }
  function handleSendMessage() {
    if (messageWrited.length) {
      const arrayMessages: Array<IMessages> = userMessages;
      if (arrayMessages) {
        arrayMessages.push({
          id: `${Math.random() * 10}`,
          message: messageWrited,
          whoSent: 'me',
          hour: handleGetCurrentTime(),
        });
        setUserMessages(arrayMessages);
      }
    }
    setMessageWrited('');
  }

  function handleViewUser(display: boolean) {
    setViewUser(display);
  }

  function handleChangeStatusAndUrgency() {
    setOcurrenceStatus(statusSelected);
    setOcurrenceUrgency(urgencySelected);
    addSuccess(`${messages[key].savedChanges}`);
  }

  return (
    <Container>
      <ToolBar page={messages[key].title}>
        <ContainerDatails>
          <div>
            <span className="user">
              {messages[key].denounced} {denounced}
            </span>
            <button type="button" onClick={() => handleViewUser(true)}>
              {messages[key].viewUser}
            </button>
          </div>
          <span>
            {messages[key].date} {date}
          </span>
          <span>
            {messages[key].urgency} {ocurrenceUrgency}
          </span>
          <span>
            {messages[key].status} {ocurrenceStatus}
          </span>
        </ContainerDatails>
      </ToolBar>

      <Content>
        <ContainerOptions>
          <div className="status">
            <span className="option">{messages[key].status} </span>
            <ContainerInput>
              <input
                id="new"
                type="radio"
                name="status"
                value="new"
                defaultChecked
                onChange={e => setStatusSelected(e.target.value)}
              />
              <label htmlFor="new">{messages[key].new}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="pending"
                type="radio"
                name="status"
                value="pending"
                onChange={e => setStatusSelected(e.target.value)}
              />
              <label htmlFor="pending">{messages[key].pending}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="closed"
                type="radio"
                name="status"
                value="closed"
                onChange={e => setStatusSelected(e.target.value)}
              />
              <label htmlFor="closed">{messages[key].closed}</label>
            </ContainerInput>
          </div>
          <div className="urgency">
            <span className="option">{messages[key].urgency} </span>
            <ContainerInput>
              <input
                id="high"
                type="radio"
                name="urgency"
                value="high"
                defaultChecked
                onChange={e => setUrgencySelected(e.target.value)}
              />
              <label htmlFor="high">{messages[key].high}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="average"
                type="radio"
                name="urgency"
                value="average"
                onChange={e => setUrgencySelected(e.target.value)}
              />
              <label htmlFor="average">{messages[key].average}</label>
            </ContainerInput>
            <ContainerInput>
              <input
                id="low"
                type="radio"
                name="urgency"
                value="low"
                onChange={e => setUrgencySelected(e.target.value)}
              />
              <label htmlFor="low">{messages[key].low}</label>
            </ContainerInput>
          </div>
          <button type="button" onClick={() => handleChangeStatusAndUrgency()}>
            {messages[key].save}
          </button>
        </ContainerOptions>
        <ContainerChat>
          <Chat>
            {userMessages.map(obj => {
              if (obj.whoSent === 'me')
                return (
                  <ContainerMessageMe key={obj.id}>
                    <div>
                      <p>{obj.message}</p>
                      <span>{obj.hour}</span>
                    </div>
                  </ContainerMessageMe>
                );
              return (
                <ContainerMessageUser key={obj.id}>
                  <div>
                    <p>{obj.message}</p>
                    <span>{obj.hour}</span>
                  </div>
                </ContainerMessageUser>
              );
            })}
          </Chat>
        </ContainerChat>
        <ContainerWriteMessage>
          <InputFile id="inputFile" type="file" />
          <ButtonAttachment htmlFor="inputFile">
            <BsPaperclip size={30} />
          </ButtonAttachment>
          <WriteMessage
            placeholder={messages[key].writeAResponse}
            value={messageWrited || ''}
            onChange={e => setMessageWrited(e.target.value)}
          />
          <ButtonSendMessage onClick={() => handleSendMessage()}>
            <IoSend size={30} />
          </ButtonSendMessage>
        </ContainerWriteMessage>
        {viewUser && (
          <PerfilExpanded
            nick={denounced}
            age="52"
            city="Juiz de Fora"
            country="Brasil"
            status="Ativo"
            state="Minas Gerais"
            key="1"
            handleClose={handleViewUser}
          />
        )}
      </Content>
    </Container>
  );
}
