import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  searchPlaceholder: string;
  addButton: string;
  removeButton: string;
  updateButton: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Presentes',
    searchPlaceholder: 'Digite o nome de um presente',
    addButton: 'Novo presente',
    removeButton: 'Remover',
    updateButton: 'Editar',
  },
  'en-US': {
    title: 'Gifts',
    searchPlaceholder: 'Enter a gift name',
    addButton: 'New gift',
    removeButton: 'Remove',
    updateButton: 'Update',
  },
};
