import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';
import { Input } from 'components/Input';
import { AiOutlineClose } from 'react-icons/ai';

export const Container = styled(DefaultPage)`
  width: 100vw;
  height: auto;
`;

export const Content = styled.div`
  width: 100%;
  overflow: scroll;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Table = styled.table`
  overflow-x: scroll;
  thead tr td {
    background-color: var(--graphite);
    color: var(--white);
    padding: 0.5rem 4rem;
    font-weight: 400;
    font-size: 1.3rem;
    border: solid 1px solid;
  }

  tr {
    color: var(--graphite);
    padding: 0.5rem 4rem;
    font-weight: 400;
    font-size: 1.3rem;
    background-color: var(--dark-gray);
  }

  tr:nth-child(even) {
    background-color: var(--gray);
  }

  td {
    text-align: center;
    padding: 1rem 1rem;
  }
`;

export const SearchFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

export const ContainerInputSearch = styled.div`
  display: ${props => props.theme.display};
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }

  div {
    width: 50%;
    background-color: var(--white);
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;

    @media (max-width: 900px) {
      width: 40%;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const InputSearch = styled.input`
  width: 90%;
  background-color: var(--white);
  border-radius: 2rem;
  color: var(--graphite);
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  height: 100%;
  border: none;
  @media (max-width: 900px) {
    width: 80%;
    font-size: 1rem;
  }
`;

export const Button = styled.button`
  color: var(--primary);
  font-size: 1.2rem;
  margin-left: 2rem;
  background-color: transparent;
  border: none;
`;

export const ContainerInputSearchAdvanced = styled.div`
  width: 100%;
  display: ${props => props.theme.display};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    border-bottom: none;
  }
`;

export const InputAdvancedFilter = styled(Input)`
  background-color: var(--white);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: var(--graphite);
  border: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 13rem;
  margin-right: 1rem;

  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem 0.8rem;
  }

  ::placeholder {
    color: var(--graphite);
  }
`;

export const SearchButton = styled.button`
  background-color: var(--primary);
  border-radius: 2rem;
  padding: 0.5rem;
  color: var(--white);
  border: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 13rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 1rem;
  }
`;

export const CloseButton = styled(AiOutlineClose)`
  position: absolute;
  right: 1rem;
  top: 5rem;
  cursor: pointer;
  fill: var(--primary);
`;

export const Select = styled.select`
  background-color: var(--white);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: var(--graphite);
  border: none;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  width: 13rem;
  margin-right: 1rem;
  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 0.3rem 0.8rem;
  }

  ::placeholder {
    color: var(--graphite);
  }
`;
