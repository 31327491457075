import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  users: string;
  ActiveUsers: string;
  inactiveUsers: string;
  reports: string;
  ocurrences: string;
  requests: string;
  registrationRequests: string;
  deletionRequests: string;
  changeRequests: string;
  extracts: string;
  gifts: string;
  exit: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Administrador',
    users: 'Usuários',
    ActiveUsers: 'Usuários ativos',
    inactiveUsers: 'Usuários inativos',
    reports: 'Denúncias',
    ocurrences: 'Ocorrências',
    requests: 'Solicitações',
    changeRequests: 'Solicitações de mudança',
    deletionRequests: 'Solicitações de exclusão',
    registrationRequests: 'Solicitações de cadastro',
    extracts: 'Extratos',
    gifts: 'Presentes',
    exit: 'Sair',
  },
  'en-US': {
    title: 'Administrator',
    users: 'Users',
    ActiveUsers: 'Active users',
    inactiveUsers: 'Inactive users',
    reports: 'Reports',
    ocurrences: 'Ocurrences',
    requests: 'Requests',
    changeRequests: 'Change requests',
    deletionRequests: 'Deletion requests',
    registrationRequests: 'Registration requests',
    extracts: 'Extracts',
    gifts: 'Gifts',
    exit: 'Exit',
  },
};
