import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from 'axios';
import { EXPIRES_TOKEN, REFRESH_TOKEN, TOKEN } from 'constants/environment';
import { IAPIResponse, IAPIResponseBodyAuthSignIn } from 'interfaces/api';

const instance: AxiosInstance = axios.create({
  baseURL: 'https://gateway.sugarbank.com.br/api/v1',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const configCopy = config;
    const accessTokenExpiration = Number(localStorage.getItem(EXPIRES_TOKEN));
    const date: number = Date.now();
    const token = localStorage.getItem(TOKEN);
    if (token) {
      configCopy.headers.Authorization = `Bearer ${token.replace(/"/g, '')}`; // necessário setar o Authorization como o token para usar no refresh token ou outras rotas
    }
    if (
      accessTokenExpiration <= date && // necessário para verificar se o token esta para expirar ou já expirou
      configCopy.url !== 'auth/refresh-token' && // necessário para tirar o loop caso o request seja do refresh token
      configCopy.url !== 'auth/sign-in' && // necessário pois no signin ele ainda não tem um token, então gera um error
      configCopy.url !== 'auth/session' // necessário pois no signin ele ainda não tem um token, então gera um error
    ) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      const response = await instance.post('auth/refresh-token', {
        refresh_token: refreshToken,
      });
      if (response.status === 200) {
        const authTokens: IAPIResponse<IAPIResponseBodyAuthSignIn> =
          response.data;
        localStorage.setItem(TOKEN, authTokens.body.result.access_token);
        localStorage.setItem(
          EXPIRES_TOKEN,
          String(authTokens.body.result.refresh_expires),
        );
        localStorage.setItem(
          REFRESH_TOKEN,
          authTokens.body.result.refresh_token,
        );
        const newToken = localStorage.getItem(TOKEN);
        if (newToken) {
          configCopy.headers.Authorization = `Bearer ${newToken}`;
        }
      }
    }
    return configCopy;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  },
);
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (
      error.response?.status === 401 &&
      originalRequest &&
      error.response &&
      originalRequest.url !== `auth/refresh-token` &&
      originalRequest.url !== 'auth/sign-in' &&
      originalRequest.url !== 'auth/session'
    ) {
      return instance
        .post('auth/refresh-token', {
          refresh_token: refreshToken,
        })
        .then(response => {
          if (response.status === 200) {
            const authTokens: IAPIResponse<IAPIResponseBodyAuthSignIn> =
              response.data;
            localStorage.setItem(TOKEN, authTokens.body.result.access_token);
            localStorage.setItem(
              EXPIRES_TOKEN,
              String(authTokens.body.result.refresh_expires),
            );
            localStorage.setItem(
              REFRESH_TOKEN,
              authTokens.body.result.refresh_token,
            );
            const token = localStorage.getItem(TOKEN);
            if (token) {
              instance.defaults.headers.Authorization = `Bearer ${token}`;
            }
          }
          return instance(originalRequest);
        });
    }
    return Promise.reject(error);
  },
);

export default instance;
