import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 50rem;

  table {
    width: 100%;
  }

  thead tr td {
    background-color: var(--graphite);
    color: var(--white);
    padding: 0.5rem 4rem;
    font-weight: 400;
    font-size: 1.3rem;
    border: solid 1px solid;
  }

  tr {
    color: var(--graphite);
    padding: 0.5rem 4rem;
    font-weight: 400;
    font-size: 1.3rem;
    background-color: var(--dark-gray);
  }

  tr:nth-child(even) {
    background-color: var(--gray);
  }

  td {
    text-align: center;
    padding: 1rem 1rem;
  }
`;
