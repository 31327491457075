import { useState, useEffect } from 'react';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { Table } from 'components/Table';
import { messages, LanguageTranslated } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

interface IDataTable {
  id: string;
  email: string;
  bank: string;
  agency: string;
  account: string;
  value: string;
  cpf: string;
}

export function ExtractRedemptionRequest(): JSX.Element {
  const [dataTable, setDataTable] = useState<Array<IDataTable>>(
    [] as Array<IDataTable>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDataTable([
      {
        id: '1',
        email: 'example@email.com',
        bank: '260',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
      {
        id: '2',
        email: 'baby@email.com',
        bank: '007',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
      {
        id: '3',
        email: 'baby2@email.com',
        bank: '210',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
      {
        id: '4',
        email: 'baby3@email.com',
        bank: '260',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
      {
        id: '5',
        email: 'baby4@email.com',
        bank: '260',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
      {
        id: '6',
        email: 'baby5@email.com',
        bank: '260',
        value: 'R$500,00',
        account: '2523-5',
        agency: '0001',
        cpf: '123.456.789.10',
      },
    ]);
  }, []);
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <td>{messages[key].email}</td>
            <td>{messages[key].bank}</td>
            <td>{messages[key].agency}</td>
            <td>{messages[key].account}</td>
            <td>{messages[key].value}</td>
            <td>{messages[key].cpf}</td>
          </tr>
        </thead>
        <tbody>
          {dataTable.map(obj => {
            return (
              <tr key={obj.id}>
                <td>{obj.email}</td>
                <td>{obj.bank}</td>
                <td>{obj.agency}</td>
                <td>{obj.account}</td>
                <td>{obj.value}</td>
                <td>{obj.cpf}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
}
