import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  selectLanguage: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    selectLanguage: 'Selecione o idioma',
  },
  'en-US': {
    selectLanguage: 'Select language',
  },
};
