import styled from 'styled-components';
import { HiMenu } from 'react-icons/hi';

export const Container = styled.div`
  min-height: 10rem;
  background: var(--secondary);
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  padding: 1rem 0rem;
  border-bottom: solid 0.1rem var(--primary);
  overflow: hidden;
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h1`
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: 300;
  margin-right: 2rem;
  margin-left: 1rem;

  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  color: var(--primary);
  font-weight: 300;
  font-size: 1.5rem;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MenuButton = styled(HiMenu)`
  display: ${props => props.theme.display};
  fill: var(--primary);
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
