import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  titleRegistration: string;
  titleChange: string;
  titleDelete: string;
  approve: string;
  denyRegistration: string;
  denyDelete: string;
  denyChange: string;
  incompabilityMessage: string;
  yes: string;
  no: string;

  // user information
  userType: string;
  age: string;
  name: string;
  email: string;
  nickname: string;
  phone: string;
  identity: string;
  cpf: string;
  income: string;
  heritage: string;
  address: string;
  city: string;
  state: string;
  country: string;
  language: string;
  haveChildrens: string;
  smoker: string;
  drink: string;
  hairColor: string;
  eyeColor: string;
  height: string;
  profession: string;
  academicBackground: string;
  instagram: string;
  facebook: string;
  tiktok: string;

  // others information
  image: string;
  copyOfCpf: string;
  copyDocumentWithPhoto: string;
  proofOfIncome: string;
  proofOfAddress: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    yes: 'Sim',
    no: 'Não',
    titleRegistration: 'Solicitação de registro - Detalhes',
    titleChange: 'Solicitação de mudança - Detalhes',
    titleDelete: 'Solicitação de exclusão - Detalhes',
    approve: 'Aprovar',
    denyChange: 'Negar mudanças',
    denyDelete: 'Negar exclusão',
    denyRegistration: 'Negar cadastro',
    incompabilityMessage:
      'Sinto muito, seu navegador não suporta reproduzir videos',

    // user information
    userType: 'Tipo de usuário: ',
    age: 'Idade: ',
    name: 'Nome: ',
    email: 'E-mail: ',
    nickname: 'Apelido: ',
    phone: 'Telefone: ',
    identity: 'Identidade: ',
    cpf: 'CPF: ',
    income: 'Renda: ',
    heritage: 'Patrimônio: ',
    address: 'Endereço: ',
    city: 'Cidade: ',
    state: 'Estado: ',
    country: 'País: ',
    language: 'Idiomas: ',
    haveChildrens: 'Tem filhos: ',
    smoker: 'Fuma: ',
    drink: 'Bebe: ',
    hairColor: 'Cor do cabelo: ',
    eyeColor: 'Cor dos olhos: ',
    height: 'Altura: ',
    profession: 'Profissão: ',
    academicBackground: 'Formação Academica: ',
    instagram: 'Instagram: ',
    facebook: 'Facebook: ',
    tiktok: 'TikTok: ',

    // others information
    image: 'Imagem: ',
    copyOfCpf: 'Cópia do CPF:',
    copyDocumentWithPhoto: 'Cópia do documento com foto:',
    proofOfIncome: 'Comprovante de renda:',
    proofOfAddress: 'Comprovante de endereço:',
  },
  'en-US': {
    yes: 'Yes',
    no: 'No',
    titleRegistration: 'Registration request',
    titleChange: 'Change request',
    titleDelete: 'Delete request',
    approve: 'Approve',
    denyChange: 'Deny changes',
    denyDelete: 'Deny delete',
    denyRegistration: 'Deny registration',
    incompabilityMessage:
      "Sorry, your browser doesn't support embedded videos.",

    // user information
    userType: 'User type: ',
    age: 'Age: ',
    name: 'Name: ',
    email: 'E-mail: ',
    nickname: 'Nickname: ',
    phone: 'Phone: ',
    identity: 'ID: ',
    cpf: 'Social number: ',
    income: 'Income: ',
    heritage: 'Heritage: ',
    address: 'Address: ',
    city: 'City: ',
    state: 'State: ',
    country: 'Country: ',
    language: 'Langugages: ',
    haveChildrens: 'Have childrens: ',
    smoker: 'Somoker: ',
    drink: 'Drink: ',
    hairColor: 'Hair color: ',
    eyeColor: 'Eye color: ',
    height: 'Height: ',
    profession: 'Profession: ',
    academicBackground: 'Academic background: ',
    instagram: 'Instagram: ',
    facebook: 'Facebook: ',
    tiktok: 'TikTok',

    // others information
    image: 'Image: ',
    copyOfCpf: 'Copy of social number: ',
    copyDocumentWithPhoto: 'Copy of ID:',
    proofOfIncome: 'Proof of income: ',
    proofOfAddress: 'Proof of address: ',
  },
};
