import { ReactNode, useState, useEffect } from 'react';
import { FaGlobeAmericas } from 'react-icons/fa';
import flagBrasil from 'assets/images/img/brl_flag.png';
import flagEua from 'assets/images/img/usd_flag.png';
import { usePageSettings } from 'hooks/pageSettings';

import { IMessage } from 'interfaces/pageSettings';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  Content,
  Header,
  Language,
  LanguagesList,
  OverlayLanguages,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface BackgroundProps {
  children?: ReactNode;
}

interface ILanguage {
  name: string;
  img: string;
  value: string;
}

function Background({ children, ...rest }: BackgroundProps): JSX.Element {
  const [showOverlayLanguages, setShowOverlayLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [languages, setLanguages] = useState<Array<ILanguage>>(
    [] as Array<ILanguage>,
  );
  const { handleSetLanguage, language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setLanguages([
      {
        name: 'Português brasileiro',
        img: flagBrasil,
        value: 'pt-BR',
      },
      {
        name: 'United States - English',
        img: flagEua,
        value: 'en-US',
      },
    ]);

    setSelectedLanguage(language);
  }, [language]);

  function handleChangeLanguage(languageSelected: string) {
    setSelectedLanguage(languageSelected);
    setShowOverlayLanguages(false);
    handleSetLanguage(languageSelected);
  }

  return (
    <Container {...rest}>
      <Content>
        <Header>
          <FaGlobeAmericas
            fill="#fff"
            size={25}
            cursor="pointer"
            onClick={() => setShowOverlayLanguages(!showOverlayLanguages)}
          />
          <p>{selectedLanguage}</p>
          <OverlayLanguages
            theme={{ display: showOverlayLanguages ? 'flex' : 'none' }}
          >
            <p>{messages[key].selectLanguage}</p>
            <LanguagesList>
              {languages.map(obj => {
                return (
                  <Language
                    key={obj.value}
                    onClick={() => handleChangeLanguage(obj.value)}
                  >
                    <img src={obj.img} alt={obj.name} />
                    <span>{obj.name}</span>
                  </Language>
                );
              })}
            </LanguagesList>
          </OverlayLanguages>
        </Header>
        {children}
      </Content>
    </Container>
  );
}

export default Background;
