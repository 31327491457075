import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { AiOutlineBell } from 'react-icons/ai';
// import { FiUsers } from 'react-icons/fi';
import { useAuth } from 'hooks/auth';
import LogoImg from 'assets/images/img/suggar_bank_logo.png';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { useMenu } from 'hooks/menu';

import { colors } from 'styles/colors';
import { messages, LanguageTranslated } from './translate';

import {
  Menu,
  MenuIcon,
  ListMenu,
  LogoContainer,
  ButtonMenu,
  Background,
  HiddenMenuIcon,
  HiddenButtonMenu,
  AdmContainer,
  Overlay,
  ButtonLogOut,
  CloseButton,
  HiddenCloseButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;
export function DrawerMenu(): JSX.Element {
  const history = useHistory();
  const { openDrawer, handleDisplayDrawer } = useMenu();
  const iconSize = 45;
  // const [showUsersButtons, setShowUsersButtons] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSolicitationsButtons, setShowSolicitationsButtons] = useState(
    false,
  );

  const { signOut } = useAuth();
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  return (
    <Menu theme={{ slide: openDrawer }}>
      <Background />

      <HiddenCloseButton />
      <LogoContainer>
        <img src={LogoImg} alt="Logo" />
        <CloseButton>
          <MdKeyboardArrowLeft
            size={70}
            fill={colors.salmon}
            cursor="pointer"
            onClick={() => handleDisplayDrawer()}
          />
        </CloseButton>
      </LogoContainer>
      <AdmContainer onClick={() => setShowOverlay(!showOverlay)}>
        <IoPersonCircleOutline size={iconSize} fill="#fff" />
        <Overlay theme={{ display: showOverlay ? 'flex' : 'none' }}>
          <ButtonLogOut onClick={() => signOut()}>
            {messages[key].exit}
          </ButtonLogOut>
        </Overlay>

        <span>{messages[key].title}</span>
      </AdmContainer>

      <ListMenu>
        {/* <div>
          <MenuIcon
            className="buttonMenu"
            onClick={() => setShowUsersButtons(!showUsersButtons)}
          >
            <FiUsers size={iconSize - 5} fill="#fff" stroke="#fff" />
            <ButtonMenu backgroundColor="primary" size="large">
              {messages[key].users}
            </ButtonMenu>
          </MenuIcon>

          <HiddenMenuIcon
            theme={{ display: showUsersButtons ? 'flex' : 'none' }}
          >
            <HiddenButtonMenu
              backgroundColor="primary"
              size="large"
              onClick={() => {
                handleDisplayDrawer();
                history.push('/usuarios-ativos');
              }}
            >
              {messages[key].ActiveUsers}
            </HiddenButtonMenu>
          </HiddenMenuIcon>

          <HiddenMenuIcon
            theme={{ display: showUsersButtons ? 'flex' : 'none' }}
          >
            <HiddenButtonMenu
              backgroundColor="primary"
              size="large"
              onClick={() => {
                handleDisplayDrawer();
                history.push('/');
              }}
            >
              {messages[key].inactiveUsers}
            </HiddenButtonMenu>
          </HiddenMenuIcon>
        </div> */}

        {/* Navegação que será liberada aos poucos, necessário verificar as importações quando usar essas opções
         <MenuIcon
          className="buttonMenu"
          onClick={() => {
            handleDisplayDrawer();
            history.push('/denuncias', { type: 'reports' });
          }}
        >
          <FiAlertTriangle size={iconSize} fill="#fff" stroke="#04121F" />
          <ButtonMenu backgroundColor="primary" size="large">
            {messages[key].reports}
          </ButtonMenu>
        </MenuIcon>

        <MenuIcon
          className="buttonMenu"
          onClick={() => {
            handleDisplayDrawer();
            history.push('/ocorrencias', { type: 'ocurrences' });
          }}
        >
          <FiAlertCircle size={iconSize - 5} fill="#fff" stroke="#04121F" />
          <ButtonMenu backgroundColor="primary" size="large">
            {messages[key].ocurrences}
          </ButtonMenu>
        </MenuIcon> */}

        <div>
          <MenuIcon
            className="buttonMenu"
            onClick={() => {
              setShowSolicitationsButtons(!showSolicitationsButtons);
            }}
          >
            <AiOutlineBell size={iconSize - 5} fill="#fff" />
            <ButtonMenu backgroundColor="primary" size="large">
              {messages[key].requests}
            </ButtonMenu>
          </MenuIcon>

          <HiddenMenuIcon
            theme={{ display: showSolicitationsButtons ? 'flex' : 'none' }}
          >
            <HiddenButtonMenu
              backgroundColor="primary"
              size="large"
              onClick={() => {
                handleDisplayDrawer();
                history.push('/solicitacoes-de-cadastro', {
                  type: 'registration',
                });
              }}
            >
              {messages[key].registrationRequests}
            </HiddenButtonMenu>
          </HiddenMenuIcon>

          {/* Navegação que será liberada aos poucos, necessário verificar as importações quando usar essas opções
          <HiddenMenuIcon
            theme={{ display: showSolicitationsButtons ? 'flex' : 'none' }}
          >
            <HiddenButtonMenu
              backgroundColor="primary"
              size="large"
              onClick={() => {
                handleDisplayDrawer();
                history.push('/solicitacoes-de-exclusao', { type: 'delete' });
              }}
            >
              {messages[key].deletionRequests}
            </HiddenButtonMenu>
          </HiddenMenuIcon>

          <HiddenMenuIcon
            theme={{ display: showSolicitationsButtons ? 'flex' : 'none' }}
          >
            <HiddenButtonMenu
              backgroundColor="primary"
              size="large"
              onClick={() => {
                handleDisplayDrawer();
                history.push('/solicitacoes-de-mudanca', { type: 'change' });
              }}
            >
              {messages[key].changeRequests}
            </HiddenButtonMenu>
          </HiddenMenuIcon> */}
        </div>
        {/* Navegação que será liberada aos poucos, necessário verificar as importações quando usar essas opções
        <MenuIcon
          className="buttonMenu"
          onClick={() => {
            handleDisplayDrawer();
            history.push('/extratos');
          }}
        >
          <AiOutlineFileText size={iconSize - 5} fill="#fff" />
          <ButtonMenu backgroundColor="primary" size="large">
            {messages[key].extracts}
          </ButtonMenu>
        </MenuIcon>
        <MenuIcon
          className="buttonMenu"
          onClick={() => {
            handleDisplayDrawer();
            history.push('/presentes');
          }}
        >
          <AiFillGift size={iconSize - 5} fill="#fff" />
          <ButtonMenu backgroundColor="primary" size="large">
            {messages[key].gifts}
          </ButtonMenu>
        </MenuIcon> */}
      </ListMenu>
    </Menu>
  );
}
