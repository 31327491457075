import { useState, useEffect } from 'react';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { Table } from 'components/Table';
import { messages, LanguageTranslated } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

interface IDataTable {
  id: string;
  user: string;
  email: string;
}

export function ExtractInactiveCustomers(): JSX.Element {
  const [dataTable, setDataTable] = useState<Array<IDataTable>>(
    [] as Array<IDataTable>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDataTable([
      {
        id: '245g4fdg5d4g54',
        user: 'José Antônio',
        email: 'example@email.com',
      },
      {
        id: '245g4fsiandi4',
        user: 'Carlos Ferreira',
        email: 'example@email.com',
      },
      {
        id: 'sdip12y8eg1bd01h',
        user: 'Márcio Santos',
        email: 'example@email.com',
      },
      {
        id: 'hd1wh7dg12h',
        user: 'Fábio Pereira',
        email: 'example@email.com',
      },
      {
        id: 'dwhy12h82h8dh',
        user: 'Marcelo dos Anjos',
        email: 'example@email.com',
      },
      {
        id: 'dn21h81h0hd1982h',
        user: 'Pedro Paulo',
        email: 'example@email.com',
      },
    ]);
  }, []);
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <td>{messages[key].id}</td>
            <td>{messages[key].user}</td>
            <td>{messages[key].email}</td>
          </tr>
        </thead>
        <tbody>
          {dataTable.map(obj => {
            return (
              <tr key={obj.id}>
                <td>{obj.id}</td>
                <td>{obj.user}</td>
                <td>{obj.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
}
