import { useState, createContext, useContext, useEffect } from 'react';
import { LANGUAGE } from 'constants/environment';

interface PageSettingsContextData {
  language: string;
  handleSetLanguage: (language: string) => void;
}

interface IPageSettingsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const PageSettingsContext = createContext({} as PageSettingsContextData);

const PageSettingsProvider = ({
  children,
}: IPageSettingsProviderProps): JSX.Element => {
  const [language, setLanguage] = useState('pt-BR');

  useEffect(() => {
    const languageDefault = localStorage.getItem(LANGUAGE);
    if (languageDefault) {
      setLanguage(languageDefault);
    } else {
      localStorage.setItem(LANGUAGE, 'pt-BR');
    }
  }, []);

  function handleSetLanguage(userLanguage: string) {
    setLanguage(userLanguage);
    localStorage.setItem(LANGUAGE, userLanguage);
  }

  return (
    <PageSettingsContext.Provider
      value={{
        language,
        handleSetLanguage,
      }}
    >
      {children}
    </PageSettingsContext.Provider>
  );
};

function usePageSettings(): PageSettingsContextData {
  const context = useContext(PageSettingsContext);

  if (!context) {
    throw new Error(
      'UsePageSettings must be used within a PageSettingsProvider',
    );
  }

  return context;
}

export { PageSettingsProvider, usePageSettings };
