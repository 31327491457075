import { useState, useEffect } from 'react';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { Table } from 'components/Table';
import { messages, LanguageTranslated } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

interface IDataTable {
  id: string;
  user: string;
  date: string;
  value: string;
}

export function ExtractMonthlyPayment(): JSX.Element {
  const [dataTable, setDataTable] = useState<Array<IDataTable>>(
    [] as Array<IDataTable>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    setDataTable([
      {
        id: '1',
        user: 'José Antônio',
        date: '22/10/2021',
        value: 'R$500,00',
      },
      {
        id: '2',
        user: 'Carlos Ferreira',
        date: '18/09/2021',
        value: 'R$500,00',
      },
      {
        id: '3',
        user: 'Márcio Santos',
        date: '18/09/2021',
        value: 'R$500,00',
      },
      {
        id: '4',
        user: 'Fábio Pereira',
        date: '18/06/2021',
        value: 'R$500,00',
      },
      {
        id: '5',
        user: 'Marcelo dos Anjos',
        date: '03/04/2021',
        value: 'R$500,00',
      },
      {
        id: '6',
        user: 'Pedro Paulo',
        date: '18/07/2021',
        value: 'R$500,00',
      },
    ]);
  }, []);
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <td>{messages[key].user}</td>
            <td>{messages[key].date}</td>
            <td>{messages[key].value}</td>
          </tr>
        </thead>
        <tbody>
          {dataTable.map(obj => {
            return (
              <tr key={obj.id}>
                <td>{obj.user}</td>
                <td>{obj.date}</td>
                <td>{obj.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
}
