import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
  denounced: string;
  claimant: string;
  date: string;
  status: string;
  denouncedProfile: string;
  messages: string;
  age: string;
  years: string;
  description: string;
  new: string;
  pending: string;
  closed: string;
  actions: string;
  save: string;
  notificateDenounced: string;
  blockDenounced: string;
  notificateClaimant: string;
  blockClaimant: string;
  sendMessage: string;
  send: string;
  cancel: string;
  blockUser: string;
  yes: string;
  no: string;
  userBlocked: string;
  messageSent: string;
  for: string;
  wantBlockUser: string;
  savedStatus: string;
  viewUser: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Denúncia - detalhes',
    denounced: 'Denunciado:',
    actions: 'Ações:',
    age: 'Idade:',
    blockClaimant: 'Bloquear requerente',
    blockDenounced: 'Bloquear denunciado',
    blockUser: 'Bloquear usuário',
    cancel: 'Cancelar',
    claimant: 'Requerente:',
    closed: 'Fechada',
    date: 'Data:',
    denouncedProfile: 'Perfil do denunciado:',
    description: 'Descrição:',
    messageSent: 'Mensagem enviada!',
    messages: 'Mensagens entre denunciado e requerente:',
    new: 'Nova',
    no: 'Não',
    notificateClaimant: 'Notificar requerente',
    notificateDenounced: 'Notificar denunciado',
    pending: 'Pendente',
    save: 'Salvar',
    send: 'Enviar',
    sendMessage: 'Enviar mensagem:',
    status: 'Status:',
    userBlocked: 'Usuário bloqueado!',
    years: 'anos',
    yes: 'Sim',
    for: 'de',
    wantBlockUser: 'Deseja realmente bloquear o usuário',
    savedStatus: 'Status salvo!',
    viewUser: 'Ver Usuário',
  },
  'en-US': {
    title: 'Report - details',
    denounced: 'Denounced:',
    actions: 'Actions:',
    age: 'Age:',
    blockClaimant: 'Block claimant',
    blockDenounced: 'Block denounced',
    blockUser: 'Block user',
    cancel: 'Cancel',
    claimant: 'Claimant:',
    closed: 'Closed',
    date: 'Date:',
    denouncedProfile: 'Denounced profile:',
    description: 'Description:',
    messageSent: 'Message sent!',
    messages: 'Mensages between claimant and denounced:',
    new: 'New',
    no: 'No',
    notificateClaimant: 'Notificate Claimant',
    notificateDenounced: 'Notificate Denounced',
    pending: 'Pending',
    save: 'Save',
    send: 'Send',
    sendMessage: 'Send message:',
    status: 'Status:',
    userBlocked: 'user blocked!',
    years: 'years',
    yes: 'yes',
    for: 'for',
    wantBlockUser: 'Do you really want to block the user',
    savedStatus: 'Saved status!',
    viewUser: 'View User',
  },
};
