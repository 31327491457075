import styled from 'styled-components';

export const Image = styled.img`
  position: relative;
  width: ${props => props.theme.largeSize};

  @media (max-width: 900px) {
    width: ${props => props.theme.smallSize};
  }
`;
