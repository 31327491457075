import { useEffect, useState } from 'react';
import { IoLocation, IoEarth } from 'react-icons/io5';
import { MdPeople, MdHourglassEmpty, MdLocationCity } from 'react-icons/md';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { colors } from 'styles/colors';
import { usePageSettings } from 'hooks/pageSettings';

import { IMessage } from 'interfaces/pageSettings';
import { messages, LanguageTranslated } from './translate';

import {
  BackgroundContainer,
  CardPerfil,
  CloseButton,
  ContainerInfo,
  Image,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IProps {
  nick: string;
  city: string;
  age: string;
  state: string;
  country: string;
  status?: 'Ativo' | 'Inativo';
  handleClose: (display: boolean) => void;
}

export function PerfilExpanded({
  nick,
  city,
  country,
  state,
  status,
  age,
  handleClose,
}: IProps): JSX.Element {
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  return (
    <BackgroundContainer>
      <CardPerfil>
        <CloseButton onClick={() => handleClose(false)} />
        <Image />
        <ContainerInfo>
          <div className="container">
            <div className="information">
              <div className="icon">
                <MdPeople size={30} fill={colors.lightGray} />
              </div>
              <div className="text">{nick}</div>
            </div>
            <div className="information">
              <div className="icon">
                <IoLocation size={30} fill={colors.lightGray} />
              </div>
              <div className="text">{city}</div>
            </div>
            <div className="information">
              <div className="icon">
                <MdHourglassEmpty size={30} fill={colors.lightGray} />
              </div>
              <div className="text">
                {age} {messages[key].age}
              </div>
            </div>

            <div className="information">
              <div className="icon">
                <MdLocationCity size={30} fill={colors.lightGray} />
              </div>
              <div className="text">{state}</div>
            </div>

            {status === 'Ativo' && (
              <div className="information">
                <div className="icon">
                  <AiOutlineCheckCircle size={30} fill={colors.success} />
                </div>
                <div className="text">{messages[key].statusActive}</div>
              </div>
            )}
            {status === 'Inativo' && (
              <div className="information">
                <div className="icon">
                  <AiOutlineCloseCircle size={30} fill={colors.danger} />
                </div>
                <div className="text">{messages[key].statusInactive}</div>
              </div>
            )}
            <div className="information">
              <div className="icon">
                <IoEarth size={30} fill={colors.lightGray} />
              </div>
              <div className="text">{country}</div>
            </div>
          </div>
        </ContainerInfo>
      </CardPerfil>
    </BackgroundContainer>
  );
}
