import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  titleRegistration: string;
  titleChange: string;
  titleDelete: string;
  placeholder: string;
  name: string;
  nick: string;
  city: string;
  age: string;
  requestDate: string;
  email: string;
  cpf: string;
  type: string;
  viewDetails: string;
  denyRegistration: string;
  denyDelete: string;
  denyChange: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    titleRegistration: 'Solicitações de registro',
    titleChange: 'Solicitações de mudança',
    titleDelete: 'Solicitações de exclusão',
    placeholder: 'Digite um nome',
    age: 'Idade:',
    requestDate: 'Data da solicitação:',
    city: 'Cidade:',
    cpf: 'CPF:',
    email: 'Email:',
    name: 'Nome:',
    nick: 'Apelido:',
    type: 'Tipo:',
    viewDetails: 'Ver detalhes',
    denyChange: 'Negar mudanças',
    denyDelete: 'Negar exclusão',
    denyRegistration: 'Negar cadastro',
  },
  'en-US': {
    titleRegistration: 'Registration request',
    titleChange: 'Change request',
    titleDelete: 'Delete request',
    placeholder: 'Put a name',
    age: 'Age:',
    requestDate: 'Request date:',
    city: 'city:',
    cpf: 'CPF:',
    email: 'Email:',
    name: 'Name:',
    nick: 'Nickname:',
    type: 'Type:',
    viewDetails: 'View details',
    denyChange: 'Deny changes',
    denyDelete: 'Deny delete',
    denyRegistration: 'Deny registration',
  },
};
