import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  title: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    title: 'Painel administrativo',
  },
  'en-US': {
    title: 'Administrative panel',
  },
};
