import styled, { keyframes } from 'styled-components';
import img from 'assets/images/img/mapa_mundi.jpg';

const rotation = keyframes`
    from {
      background-position: left center;
    }
    to {
      background-position: -355.56px center;
    }
  `;

export const Container = styled.div`
  position: absolute;
  align-self: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;

  box-shadow: -30px -20px 0 0 rgba(0, 0, 0, 0.1) inset,
    -70px -30px 0 0 rgba(0, 0, 0, 0.1) inset,
    -5px -5px 0 0 rgba(4, 18, 31, 0.2) inset;

  background: url(${img});
  background-size: cover;
  animation: ${rotation} 5s infinite linear;
`;
