import styled from 'styled-components';
import DefaultPage from 'pages/App/_DefaultPage';

export const Container = styled(DefaultPage)`
  width: 100vw;
  height: auto;
`;

export const ContainerFilter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
`;

export const ContainerDate = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 1.2rem;
    margin-right: 1rem;
    color: var(--light-gray);
  }

  input {
    border-radius: 10px;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    margin-right: 1.5rem;
  }

  input.day {
    width: 5rem;
  }

  input.year {
    width: 7rem;
  }

  select {
    border-radius: 10px;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    margin-right: 1.5rem;
    width: 10rem;

    option {
      font-size: 1.2rem;
      padding: 0.5rem 1rem;
    }
  }
`;

export const SelectOption = styled.select`
  border-radius: 10px;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  margin-right: 1.5rem;
  width: 17rem;
  margin-top: 2rem;

  option {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 2rem 0;
  background-color: var(--secondary);
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: auto;
  margin-top: 3rem;
  overflow: auto;
`;

export const ButtonExportPdf = styled.button`
  background-color: var(--primary);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  color: var(--light-gray);
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  border: none;
  border-radius: 1rem;
`;
