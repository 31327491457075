import { useEffect, useState } from 'react';
import { ToolBar } from 'components/ToolBar';

import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { ExtractMonthlyPayment } from './ExtractMonthlyPayment';
import { ExtractRedemptionRequest } from './ExtractRedemptionRequest';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  ContainerFilter,
  ContainerDate,
  SelectOption,
  Content,
  ContainerTable,
  ButtonExportPdf,
} from './styles';
import { ExtractCreditPurchase } from './ExtractCreditPurchase';
import { ExtractSendCredits } from './ExtractSendCredits';
import { ExtractInactiveCustomers } from './ExtractInactiveCustomers';
import { ExtractGiftsValues } from './ExtractGiftsValues';

type Key = keyof IMessage<LanguageTranslated>;

interface IMonth {
  month: string;
  code: number;
}

interface IExtractOption {
  extract: string;
  id: string;
}

export function Extracts(): JSX.Element {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [extractSelected, setExtractSelected] = useState(1);
  const [months, setMonths] = useState<Array<IMonth>>([] as Array<IMonth>);
  const [extractOption, setExtractOption] = useState<Array<IExtractOption>>(
    [] as Array<IExtractOption>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');

  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  function handleGetCurrentDate() {
    const date = new Date();
    const currentDay = date.getDate().toString();
    const currentMonth = (date.getMonth() + 1).toString();
    const currentYear = date.getFullYear().toString();
    setDay(currentDay);
    setMonth(currentMonth);
    setYear(currentYear);
  }

  useEffect(() => {
    handleGetCurrentDate();
    setExtractOption([
      {
        id: '1',
        extract: messages[key].monthlyPayment,
      },
      {
        id: '2',
        extract: messages[key].creditPurchase,
      },
      {
        id: '3',
        extract: messages[key].sendCredits,
      },
      {
        id: '4',
        extract: messages[key].redemptionRequests,
      },
      {
        id: '5',
        extract: messages[key].inactiveCustomers,
      },
      {
        id: '6',
        extract: messages[key].giftsValues,
      },
    ]);

    setMonths([
      {
        month: messages[key].january,
        code: 1,
      },
      {
        month: messages[key].february,
        code: 2,
      },
      {
        month: messages[key].march,
        code: 3,
      },
      {
        month: messages[key].april,
        code: 4,
      },
      {
        month: messages[key].may,
        code: 5,
      },
      {
        month: messages[key].june,
        code: 6,
      },
      {
        month: messages[key].july,
        code: 7,
      },
      {
        month: messages[key].august,
        code: 8,
      },
      {
        month: messages[key].september,
        code: 9,
      },
      {
        month: messages[key].october,
        code: 10,
      },
      {
        month: messages[key].november,
        code: 11,
      },
      {
        month: messages[key].december,
        code: 12,
      },
    ]);
  }, [key]);

  function handleCreatePdf() {
    const table = document.querySelector('table')?.innerHTML;
    let style = '<style>';
    style += 'p {width: 100%;font: Arial; font-size: 30px; padding-left: 20px}';
    style += 'table {width: 100%;font: Arial;}';
    style +=
      'table, th, td {border: solid 1px #000; border-collapse: collapse;';
    style += 'text-align: center;}';
    style +=
      'thead tr td {padding: 5px 20px; font-weight: 400; font-size: 20px; border: solid 1px solid; font-size: 22px}';
    style +=
      'tbody tr td {padding: 5px 20px;font-weight: 400;font-size: 20px;}';
    style += '</style>';

    const win = window.open('', '', 'height=700,width=700');
    if (win) {
      win.document.write('<html><head>');
      win.document.write(`<title>${extractSelected}</title>`); // <title> CABEÇALHO DO PDF.
      win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
      win.document.write('</head>');
      win.document.write('<body>');
      win.document.write(
        `<p>${messages[key].extractsOfDay} ${day}/${month}/${year} - ${extractSelected}</p>`,
      );
      win.document.write('<table>');
      win.document.write(`${table}`); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
      win.document.write('</table></p></html>');
      win.document.close(); // FECHA A JANELA
      win.print(); // IMPRIME O CONTEÚDO
    }
  }

  return (
    <Container>
      <ToolBar page={messages[key].title}>
        <ContainerFilter>
          <ContainerDate>
            <span>{messages[key].day}</span>
            <input
              className="day"
              type="number"
              min="1"
              max="31"
              value={day}
              onChange={e => {
                if (Number(e.target.value) > 31) setDay('31');
                else if (Number(e.target.value) < 1) setDay('');
                else setDay(e.target.value);
              }}
            />
            <span>{messages[key].month}</span>
            <select value={month} onChange={e => setMonth(e.target.value)}>
              {months.map(obj => (
                <option key={obj.code} value={obj.code}>
                  {obj.month}
                </option>
              ))}
            </select>
            <span>{messages[key].year}</span>
            <input
              className="year"
              type="number"
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </ContainerDate>
          <SelectOption
            value={extractSelected}
            onChange={e => setExtractSelected(Number(e.target.value))}
          >
            {extractOption.map(obj => (
              <option key={obj.id} value={obj.id}>
                {obj.extract}
              </option>
            ))}
          </SelectOption>
        </ContainerFilter>
      </ToolBar>
      <ContainerTable>
        {extractSelected === 1 && <ExtractMonthlyPayment />}
        {extractSelected === 2 && <ExtractCreditPurchase />}
        {extractSelected === 3 && <ExtractSendCredits />}
        {extractSelected === 4 && <ExtractRedemptionRequest />}
        {extractSelected === 5 && <ExtractInactiveCustomers />}
        {extractSelected === 6 && <ExtractGiftsValues />}

        <ButtonExportPdf onClick={() => handleCreatePdf()}>
          {messages[key].exportPdf}
        </ButtonExportPdf>
      </ContainerTable>
      <Content />
    </Container>
  );
}
