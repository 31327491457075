import { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: string;
  size?: 'small' | 'regular' | 'large';
  backgroundColor?: 'primary' | 'secondary';
}

export function Button({
  loading = false,
  children,
  size = 'small',
  backgroundColor = 'primary',
  ...rest
}: ButtonProps): JSX.Element {
  return (
    <Container
      size={size}
      disabled={loading}
      {...rest}
      theme={{ buttonColor: backgroundColor }}
    >
      <span>{loading ? 'Carregando...' : children}</span>
    </Container>
  );
}
