import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--blue-dark);
  overflow: auto;
`;

export const Content = styled.div`
  margin-left: ${props => props.theme.left};
  height: 100%;
  background: var(--blue-dark);
  align-items: center;
  min-width: 40rem;
  transition: all 0.2s linear;
`;
