import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  email: string;
  bank: string;
  account: string;
  agency: string;
  value: string;
  cpf: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    email: 'Email',
    bank: 'Banco',
    value: 'Valor',
    account: 'Conta',
    agency: 'Agência',
    cpf: 'CPF',
  },
  'en-US': {
    email: 'Email',
    bank: 'Bank',
    value: 'Value',
    account: 'Account',
    agency: 'Agency',
    cpf: 'CPF',
  },
};
