import { createGlobalStyle } from 'styled-components';
import { colors } from 'styles/colors';

export const GlobalStyles = createGlobalStyle`
    :root {
        --blue-dark: ${colors.blueDark};
        --blue-regular: ${colors.blueRegular};
        --blue-card: ${colors.blueCard};
        --blue: ${colors.blue};
        --blue-light: ${colors.blueLight};
        --black: ${colors.black};
        --pink: ${colors.pink};
        --salmon: ${colors.salmon};
        --graphite: ${colors.graphite};
        --white: ${colors.white};
        --light-gray: ${colors.lightGray};
        --gray: ${colors.gray};
        --gray-two: ${colors.grayTwo};
        --dark-gray: ${colors.darkGray};
        --success: ${colors.success};
        --danger: ${colors.danger};
        --remove: ${colors.remove};
        --primary: ${colors.salmon};
        --secondary: ${colors.blueDark};
        --orange: ${colors.orange};
        --yellow: ${colors.yellow};
        --purple: ${colors.purple};
        --blue-report: ${colors.blueReport};


    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        color: var(--graphite);
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body {
        -webkit-font-smoothing: antialised;
    }

    body, input, textarea, button {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 700;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    /* Works on Firefox */
    * {
    scrollbar-width: thin;
    scrollbar-color:  var(--blue-aqua) var(--white);
    }
      /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 0.3125rem;
      height: 0.6125rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--salmon);
      border-radius: 1.25rem;
    }
`;
