import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import api from 'services/api';
import { User } from 'interfaces/user';
import { EXPIRES_TOKEN, REFRESH_TOKEN, TOKEN } from 'constants/environment';
import axios from 'axios';
import { useToast } from './toast';

interface SignInCredentials {
  login: string;
  password: string;
}

interface IData {
  token: string;
  user: User;
}

interface AuthContextData {
  data: IData | undefined;
  loading: boolean;
  signIn: (credentials: SignInCredentials) => void;
  signOut: () => void;
}

interface IAuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: IAuthProviderProps): JSX.Element => {
  const [data, setData] = useState<IData>();
  const [loading, setLoading] = useState(false);
  const { handleApiError, addError } = useToast();
  useEffect(() => {
    async function loadStoragedData() {
      const token = localStorage.getItem('@suggar-bank-admin:token');

      if (token) {
        const response = await api.get('auth/session');

        setData({
          token,
          user: response.data?.result.user,
        });
      }
    }

    loadStoragedData();
  }, []);

  const signIn = useCallback(
    async ({ login, password }) => {
      try {
        setLoading(true);
        const responseSignIn = await api.post('auth/sign-in', {
          email: login,
          password,
          grant_type: 'password',
        });
        if (responseSignIn.data?.result.access_token) {
          api.defaults.headers.authorization = `Bearer ${responseSignIn.data?.result.access_token}`;
          const responseSession = await api.get('auth/session');
          setData({
            token: responseSignIn.data?.result.access_token,
            user: responseSession.data?.result.user,
          });
          localStorage.setItem(
            TOKEN,
            JSON.stringify(responseSignIn.data?.result.access_token),
          );
          localStorage.setItem(
            EXPIRES_TOKEN,
            JSON.stringify(responseSignIn.data?.result.refresh_expires),
          );
          localStorage.setItem(
            REFRESH_TOKEN,
            JSON.stringify(responseSignIn.data?.result.refresh_token),
          );
        }
      } catch (err) {
        if (axios.isAxiosError(err))
          if (err.response?.status === 400) {
            addError('Usuário e/ou senha inválidos');
          } else {
            handleApiError(err);
          }
      } finally {
        setLoading(false);
      }
    },
    [handleApiError, addError],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRES_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setData({} as IData);
  }, []);

  return (
    <AuthContext.Provider value={{ data, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
