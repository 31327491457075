import { createContext, useContext, useState } from 'react';

interface MenuContextData {
  openDrawer: boolean;
  handleDisplayDrawer: () => void;
}

interface IMenuProviderProps {
  children: JSX.Element | JSX.Element[];
}

const MenuContext = createContext({} as MenuContextData);

const MenuProvider = ({ children }: IMenuProviderProps): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(true); // Depois alterar para false para iniciar com o menu oculto
  function handleDisplayDrawer() {
    setOpenDrawer(!openDrawer);
  }
  return (
    <MenuContext.Provider value={{ openDrawer, handleDisplayDrawer }}>
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return context;
}

export { MenuProvider, useMenu };
