import styled from 'styled-components';

export const Container = styled.div`
  background: var(--blue-regular);
  flex: 2 1 400px;
  border-radius: 2rem 2rem 0 0;
  max-width: 400px;
  padding: 4rem;
  margin-top: 3rem;
  align-self: flex-start;
`;
