import styled from 'styled-components';

interface ButtonProps {
  size: 'small' | 'regular' | 'large';
}

interface ISizes {
  [key: string]: string;
}

const heights: ISizes = {
  small: '1.5rem',
  regular: '3.125rem',
  large: '6rem',
};

const fontSizes: ISizes = {
  small: '0.9rem',
  regular: '1.2rem',
  large: '1.5rem',
};

const paddings: ISizes = {
  small: '0.1rem',
  regular: '0.5rem',
  large: '1.5rem',
};

export const Container = styled.button<ButtonProps>`
  background-color: var(--${props => props.theme.buttonColor});
  width: 100%;
  height: 3.71rem;
  border-radius: 25px 25px 5px 5px;
  padding: 0 ${({ size }) => paddings[size]};
  border: none;
  box-sizing: border-box;
  margin-bottom: 10px;
  height: ${({ size }) => heights[size]};

  span {
    font-size: ${({ size }) => fontSizes[size]};
    color: var(--white);
  }
`;
