import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-width: 720px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: var(--blue-dark);
  align-items: center;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    border-radius: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 3rem 1rem 1rem;
  text-align: center;

  p {
    font-family: Roboto;
    color: var(--white);
    font-weight: 400;
    font-size: 1rem;
    filter: drop-shadow(0 2px 2px black);
    margin-left: 1rem;
  }
`;

export const LogoContainer = styled.div`
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--white);
  border: 3px solid var(--blue-regular);

  @media (max-width: 1080px) {
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  img {
    width: 70%;
  }
`;

export const OverlayLanguages = styled.div`
  position: absolute;
  width: 25rem;
  border-radius: 15px;
  display: ${props => props.theme.display};
  flex-direction: column;
  background-color: var(--light-gray);
  top: 5rem;
  z-index: 99999999999;

  @media (max-width: 430px) {
    width: 20rem;
  }

  p {
    width: 100%;
    background-color: var(--graphite);
    text-align: center;
    font-size: 1.3rem;
    padding: 0.5rem 0;
    color: var(--white);
    margin: 0;
    border-radius: 14px 14px 0 0;
    margin-bottom: 1rem;
  }
`;

export const LanguagesList = styled.ul`
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
`;

export const Language = styled.li`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  cursor: pointer;

  @media (max-width: 430px) {
    padding-left: 1rem;
  }

  :hover {
    background-color: #56ccf2;
  }

  span {
    font-size: 1.3rem;
    padding-left: 2rem;

    @media (max-width: 430px) {
      padding-left: 1rem;
    }
  }

  img {
    width: 50px;
  }
`;
