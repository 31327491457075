import { useEffect, useState } from 'react';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import { useMenu } from 'hooks/menu';
import { messages, LanguageTranslated } from './translate';
import {
  Container,
  Text,
  Title,
  ContainerText,
  Content,
  MenuButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface TollBarProps {
  page?: string;
  children?: JSX.Element;
}

export function ToolBar({
  page = 'usuários ativos',
  children,
}: TollBarProps): JSX.Element {
  const { language } = usePageSettings();
  const { handleDisplayDrawer, openDrawer } = useMenu();
  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);
  return (
    <Container>
      <ContainerText>
        <MenuButton
          theme={{ display: openDrawer ? 'block' : 'none' }}
          onClick={() => handleDisplayDrawer()}
        />
        <Title>{messages[key].title}</Title>
        <Text>{page}</Text>
      </ContainerText>
      <Content>{children}</Content>
    </Container>
  );
}
