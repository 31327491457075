import { usePageSettings } from 'hooks/pageSettings';
import { useEffect, useState } from 'react';
import { IMessage } from 'interfaces/pageSettings';
import { Container } from './styles';
import { EarthRotation } from '../EarthRotation/index';
import { LanguageTranslated } from '../../pages/App/RequestsDetails/translate';
import { messages } from './translate';

type Key = keyof IMessage<LanguageTranslated>;

export function Loading(): JSX.Element {
  const { language } = usePageSettings();

  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);
  return (
    <Container>
      {messages[key].loading}...
      <div>
        <EarthRotation />
      </div>
    </Container>
  );
}
