/* eslint-disable no-underscore-dangle */
import { ToolBar } from 'components/ToolBar';
import { FaSearch } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import api from 'services/api';
import { getFullDate } from 'utils/getFullDate';
import { Loading } from 'components/Loading';
import { messages, LanguageTranslated } from './translate';

import {
  Container,
  SearchFilter,
  InputSearch,
  ContainerInputSearch,
  ContainerCards,
  ContainerCard,
  ContainerText,
  ContainerButtons,
  CardButton,
} from './styles';

type Key = keyof IMessage<LanguageTranslated>;

interface IUserData {
  _id: string;
  name: string;
  nickname: string;
  age: string;
  email: string;
  document: string;
  address: { city: string };
  role: string;
  createdAt: string;
}

interface IRequestProps {
  type: 'registration' | 'delete' | 'change';
}

export function Requests(): JSX.Element {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation<IRequestProps>();
  const history = useHistory();
  const [requests, setRequests] = useState<Array<IUserData>>(
    [] as Array<IUserData>,
  );
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);

  useEffect(() => {
    async function handleGetRequests() {
      const response = await api.get<IUserData[]>(
        `/admin/users?status=pending`,
      );
      setRequests(response.data);
    }
    handleGetRequests();
  }, [loading]);

  function handleViewDetails(userData: IUserData) {
    history.push('detalhes-da-solicitacao', {
      userData,
      type: location.state?.type,
    });
    // passar obj inteiro dentro do map
  }
  async function handleDenyButton(_id: string) {
    setLoading(true);
    await api.post(`/admin/users/${_id}/deny`);
    setLoading(false);
  }

  function handleSelectButtonType(_id: string) {
    if (location.state?.type === 'registration')
      return (
        <CardButton onClick={() => handleDenyButton(_id)}>
          {messages[key].denyRegistration}
        </CardButton>
      );
    if (location.state?.type === 'change')
      return <CardButton>{messages[key].denyChange}</CardButton>;
    if (location.state?.type === 'delete')
      return <CardButton>{messages[key].denyDelete}</CardButton>;
    return (
      <CardButton onClick={() => handleDenyButton(_id)}>
        {messages[key].denyRegistration}
      </CardButton>
    );
  }
  function handleSelectPageTitle() {
    if (location.state?.type === 'registration')
      return messages[key].titleRegistration;
    if (location.state?.type === 'change') return messages[key].titleChange;
    if (location.state?.type === 'delete') return messages[key].titleDelete;
    return messages[key].titleRegistration;
  }

  return (
    <Container>
      <ToolBar page={handleSelectPageTitle()}>
        <SearchFilter>
          <ContainerInputSearch>
            <div>
              <InputSearch
                placeholder={messages[key].placeholder}
                name="searchInput"
                onChange={e => setName(e.target.value)}
              />
              <FaSearch size={30} fill="#FA6379" cursor="pointer" />
            </div>
          </ContainerInputSearch>
        </SearchFilter>
      </ToolBar>
      {loading ? (
        <Loading />
      ) : (
        <ContainerCards>
          {requests
            .filter(obj => obj.name.toLowerCase().includes(name.toLowerCase()))
            .map(obj => {
              return (
                <ContainerCard key={obj._id}>
                  <ContainerText>
                    <span>
                      {messages[key].name} {obj.name}
                    </span>
                    <span>
                      {messages[key].nick} {obj.nickname}
                    </span>
                    <span>
                      {messages[key].age} {obj.age}
                    </span>
                    <span>
                      {messages[key].email} {obj.email}
                    </span>
                  </ContainerText>
                  <ContainerText>
                    <span>
                      {messages[key].cpf} {obj.document}
                    </span>
                    <span>
                      {messages[key].city} {obj.address.city}
                    </span>
                    <span>
                      {messages[key].requestDate} {getFullDate(obj.createdAt)}
                    </span>
                    <span>
                      {messages[key].type} {obj.role}
                    </span>
                  </ContainerText>
                  <ContainerButtons>
                    <CardButton onClick={() => handleViewDetails(obj)}>
                      {messages[key].viewDetails}
                    </CardButton>
                    {handleSelectButtonType(obj._id)}
                  </ContainerButtons>
                </ContainerCard>
              );
            })}
        </ContainerCards>
      )}
    </Container>
  );
}
