import { ReactNode } from 'react';
import { DrawerMenu } from 'components/Menu';
import { useMenu } from 'hooks/menu';
import { Container, Content } from './styles';

interface BackgroundProps {
  children?: ReactNode;
}

function DefaultPage({ children, ...rest }: BackgroundProps): JSX.Element {
  const { openDrawer } = useMenu();
  return (
    <Container {...rest}>
      <DrawerMenu />

      <Content theme={{ left: openDrawer ? '0rem' : '25rem' }}>
        {children}
      </Content>
    </Container>
  );
}

export default DefaultPage;
