import styled from 'styled-components';

export const Container = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 40px;
  color: var(--white);
  div {
    padding: 20px 0;
    height: 200px;
    width: 200px;
  }
`;
