import logo from 'assets/images/img/suggar_bank_logo.png';
import { Image } from './styles';

interface ImageProps {
  size: number;
}

export function Logo({ size }: ImageProps): JSX.Element {
  const large = `${size}px`;
  const small = `${size * 0.7}px`;
  return (
    <Image
      src={logo}
      theme={{ largeSize: large, smallSize: small }}
      alt="Suggar Bank"
    />
  );
}
