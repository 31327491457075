import React from 'react';
import { Container } from './styles';

interface BackgroundProps {
  children?: JSX.Element;
}

export function ContainerSignUp({ children }: BackgroundProps): JSX.Element {
  return <Container>{children}</Container>;
}
