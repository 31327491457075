import { IMessage } from 'interfaces/pageSettings';

export interface LanguageTranslated {
  loading: string;
}

export const messages: IMessage<LanguageTranslated> = {
  'pt-BR': {
    loading: 'Carregando',
  },
  'en-US': {
    loading: 'Loading',
  },
};
