/* eslint-disable no-underscore-dangle */
import { ToolBar } from 'components/ToolBar';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePageSettings } from 'hooks/pageSettings';
import { IMessage } from 'interfaces/pageSettings';
import api from 'services/api';

import { messages, LanguageTranslated } from './translate';

import { Container, Details, Section, Button } from './styles';
import { IUserData } from './interfaces';

type Key = keyof IMessage<LanguageTranslated>;

interface IRequestProps {
  userData: IUserData;
  type: 'registration' | 'delete' | 'change';
}

export function RequestsDetails(): JSX.Element {
  const location = useLocation<IRequestProps>();
  const history = useHistory();
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const { language } = usePageSettings();
  const [key, setKey] = useState<Key>('pt-BR');
  useEffect(() => {
    if (language === 'pt-BR') setKey('pt-BR');
    if (language === 'en-US') setKey('en-US');
  }, [language]);
  useEffect(() => {
    setUserData(location.state.userData);
  }, [location.state.userData]);

  function handleSelectPageTitle() {
    if (location.state.type === 'registration')
      return messages[key].titleRegistration;
    if (location.state.type === 'change') return messages[key].titleChange;
    if (location.state.type === 'delete') return messages[key].titleDelete;
    return messages[key].titleRegistration;
  }
  function handleSelectButtonDenyTitle() {
    if (location.state.type === 'registration')
      return messages[key].denyRegistration;
    if (location.state.type === 'change') return messages[key].denyChange;
    if (location.state.type === 'delete') return messages[key].denyDelete;
    return messages[key].denyRegistration;
  }
  async function handleDenyButton(_id: string) {
    await api.post(`/admin/users/${_id}/deny`);
  }

  return (
    <Container>
      <ToolBar page={handleSelectPageTitle()} />
      <Details>
        <Section>
          <div className="grid userInformation">
            <div className="groupItem">
              <div className="item">
                <span className="label">{messages[key].userType} </span>
                <span className="information"> {userData.role}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].age} </span>
                <span className="information"> {userData.age}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].name} </span>
                <span className="information"> {userData.name}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].email} </span>
                <span className="information"> {userData.email}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].nickname} </span>
                <span className="information"> {userData.nickname}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].phone} </span>
                <span className="information"> {userData.phone}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].identity} </span>
                <span className="information">{userData.identityDocument}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].cpf} </span>
                <span className="information"> {userData.document}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].income} </span>
                <span className="information">{userData.metadata?.income}</span>
              </div>
            </div>
            <div className="groupItem">
              <div className="item">
                <span className="label">{messages[key].heritage} </span>
                <span className="information">{userData.metadata?.income}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].address} </span>
                <span className="information"> {userData.address?.cep}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].city} </span>
                <span className="information"> {userData.address?.city}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].state} </span>
                <span className="information"> {userData.address?.state}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].country} </span>
                <span className="information">{userData.address?.country}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].language} </span>
                <span className="information">
                  {userData.metadata?.languages}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].haveChildrens} </span>
                <span className="information">
                  {userData.metadata?.haveChildren
                    ? messages[key].yes
                    : messages[key].no}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].smoker} </span>
                <span className="information">
                  {userData.metadata?.isSmoker
                    ? messages[key].yes
                    : messages[key].no}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].drink} </span>
                <span className="information">
                  {userData.metadata?.doDrink
                    ? messages[key].yes
                    : messages[key].no}
                </span>
              </div>
            </div>
            <div className="groupItem">
              <div className="item">
                <span className="label">{messages[key].hairColor} </span>
                <span className="information">
                  {userData.metadata?.hairColor.color}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].eyeColor} </span>
                <span className="information">
                  {userData.metadata?.eyesColor.color}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].height} </span>
                <span className="information">{userData.metadata?.height}</span>
              </div>
              <div className="item">
                <span className="label">{messages[key].profession} </span>
                <span className="information">
                  {userData.metadata?.profession}
                </span>
              </div>
              <div className="item">
                <span className="label">
                  {messages[key].academicBackground}
                </span>
                <span className="information">
                  {userData.metadata?.academicFormation}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].instagram} </span>
                <span className="information">
                  {userData.socialMedias?.instagram}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].facebook} </span>
                <span className="information">
                  {userData.socialMedias?.facebook}
                </span>
              </div>
              <div className="item">
                <span className="label">{messages[key].tiktok} </span>
                <span className="information">
                  {userData.socialMedias?.tiktok}
                </span>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="grid userPhotos">
            {userData.files?.photosUrls.map(file => {
              return (
                <div className="fileItem">
                  <img src={file} alt="userImage" />
                </div>
              );
            })}

            <div className="fileItem">
              <img src={userData.files?.photoPosterUrl} alt="userImage" />
            </div>
            <div className="fileItem">
              <video controls>
                <source src={userData.files?.videoUrl} type="video/mp4" />
                <track
                  default
                  src="captions_en.vtt"
                  kind="captions"
                  srcLang="en"
                />
                {messages[key].incompabilityMessage}
              </video>
            </div>
          </div>
        </Section>
        <Section>
          <div className="grid userDocuments">
            <div className="fileItem">
              <span className="label">{messages[key].copyOfCpf} </span>
              <img src={userData.files?.documentProveUrl} alt="userImage" />
            </div>
            <div className="fileItem">
              <span className="label">
                {messages[key].copyDocumentWithPhoto}
              </span>
              <img
                src={userData.files?.documentWithPhotoProveUrl}
                alt="userImage"
              />
            </div>
            <div className="fileItem">
              <span className="label">{messages[key].proofOfIncome} </span>
              <img src={userData.files?.incomeProveUrl} alt="userImage" />
            </div>
            <div className="fileItem">
              <span className="label">{messages[key].proofOfAddress} </span>
              <img src={userData.files?.addressProveUrl} alt="userImage" />
            </div>
          </div>
        </Section>
        <div className="buttons">
          <Button
            onClick={async () => {
              await api.post(`/admin/users/${userData._id}/approve`);
              history.push('home');
            }}
          >
            {messages[key].approve}
          </Button>
          <Button
            onClick={async () => {
              await api.post(`/admin/users/${userData._id}/deny`);
              history.push('home');
            }}
          >
            {handleSelectButtonDenyTitle()}
          </Button>
        </div>
      </Details>
    </Container>
  );
}
