import { Switch } from 'react-router-dom';

import { SignIn } from 'pages/Auth/SignIn';

import { MenuProvider } from 'hooks/menu';

import { HomePage } from 'pages/App/HomePage';
import { ActiveUsers } from 'pages/App/ActiveUsers';
import { ReportsAndOcurrences } from 'pages/App/ReportsAndOcurrences';
import { ReportDetails } from 'pages/App/ReportDetails';
import { OcurrenceDetails } from 'pages/App/OcurrenceDetails';
import { Requests } from 'pages/App/Requests';
import { Extracts } from 'pages/App/Extracts';
import { RequestsDetails } from 'pages/App/RequestsDetails';
import { Gifts } from 'pages/App/Gifts';
import { Route } from './Route';

export const Routes = (): JSX.Element => {
  return (
    <Switch>
      <MenuProvider>
        <Route path="/" exact component={SignIn} />
        <Route path="/home" isPrivate component={Requests} />
        <Route path="/usuarios-ativos" isPrivate component={ActiveUsers} />
        <Route path="/denuncias" isPrivate component={ReportsAndOcurrences} />
        <Route path="/ocorrencias" isPrivate component={ReportsAndOcurrences} />
        <Route path="/denuncia-detalhes" isPrivate component={ReportDetails} />
        <Route path="/extratos" isPrivate component={Extracts} />
        <Route path="/presentes" isPrivate component={Gifts} />
        <Route
          path="/solicitacoes-de-cadastro"
          isPrivate
          component={Requests}
        />
        <Route
          path="/solicitacoes-de-exclusao"
          isPrivate
          component={Requests}
        />
        <Route path="/solicitacoes-de-mudanca" isPrivate component={Requests} />
        <Route
          path="/detalhes-da-solicitacao"
          isPrivate
          component={RequestsDetails}
        />
        <Route
          path="/ocorrencia-detalhes"
          isPrivate
          component={OcurrenceDetails}
        />
      </MenuProvider>
    </Switch>
  );
};
